.terms-condition {
    width: 100%;
}

.terms-container {
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    max-width: 80%;
    margin: 0 auto;
    padding: 20px 0;
    margin-bottom: 3rem;
}

.terms-condition .footer-container {
    margin: 0 auto;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding: 32px 0px;
    width: 100%;
    background: linear-gradient(180deg, rgb(10.46, 31.8, 72.71) 0%, rgb(56.18, 99.87, 183.6) 100%);
}

.terms-condition .footer-elements-container {
    width: 88%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.terms-condition .footer-elements {
    flex: 0 0 auto !important;
}

.terms-condition .footer-section-elements {
    color: var(--globalbasewhite) !important;
}

@media screen and (min-width: 768px) {
    .terms-container {
        width: 60%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
        max-width: 80%;
        margin: 0 auto;
        padding: 20px;
        margin-bottom: 3rem;
    }

    .terms-condition .footer-container {
        align-items: center;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        padding: 32px 0px;
        width: 100%;
        background: linear-gradient(180deg, rgb(10.46, 31.8, 72.71) 0%, rgb(56.18, 99.87, 183.6) 100%);
    }

    .terms-condition .footer-elements-container {
        width: 88%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }

}

.terms-container h1 {
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: -0.18px;
    line-height: 32px;
    text-align: center;
}

.terms-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.terms-content h2 {
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.18px;
    line-height: 22px;
}

.terms-content p {
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.18px;
    line-height: 22px;
}

.terms-content ul {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

li {
    list-style-type: disc;
    list-style-position: inside;
}

hr {
    margin: 20px 0;
}