.form-page {
    background-color: #ffffff;
    height: fit-content;
    width: 85%;
    gap: 4rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 3rem;
}

.header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    width: 100%;
    /* flex-wrap: wrap; */
    /* gap: 1.5rem; */
}

.kokoroko-logo {
    height: 42px !important;
    width: 124px !important;
}

.header-links {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 32px;
}

.text-wrapper-2 {
    color: var(--kokorkoblackblack-400);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    white-space: nowrap;
    width: fit-content;
}

.buttons-primary-2 {
    align-items: center;
    background-color: var(--kokorkoblueprimary-500);
    border-radius: 6px;
    /* display: inline-flex; */
    display: none;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    overflow: hidden;
    padding: 8px 12px;
    cursor: pointer;
}

.button-text-3 {
    color: var(--globalbasewhite);
    font-family: var(--medium-12-font-family);
    font-size: var(--medium-12-font-size);
    font-style: var(--medium-12-font-style);
    font-weight: var(--medium-12-font-weight);
    letter-spacing: var(--medium-12-letter-spacing);
    line-height: var(--medium-12-line-height);
    margin-top: -1px;
    white-space: nowrap;
    width: fit-content;
}

.form-container {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    gap: 3.5rem;
    margin: 0 auto;
    flex-wrap: wrap;
}

.form-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
}

.form-title {
    align-self: stretch;
    color: var(--Kokorko-Black-black-400, #222);
    font-family: Inter;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 3.6rem */
    letter-spacing: -0.09rem;
    
}

.form-subtitle {
    align-self: stretch;
    color: var(--Kokorko-Black-black-300, #545454);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 475;
    line-height: 1.375rem; /* 137.5% */
    letter-spacing: -0.01125rem;
}

.form-inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 3.5rem;
    border-radius: 1rem;
    border: 1px solid #EBEBEB;
    background: var(--Kokorko-White, #FAFAFA);
}

.form-inputs form {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    align-self: stretch;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    /* border: 1px solid red; */
}

.input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
}

.input-label {
    align-self: stretch;
    color: var(--Kokorko-Black-black-300, #545454);
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 475;
    line-height: 1.25rem; /* 142.857% */
    letter-spacing: -0.00313rem;
}

.input-field {
    display: flex;
    width: 100% !important;
    height: 3.125rem;
    padding: 0.1875rem 0.5rem 0.1875rem 0.75rem;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 1px solid var(--Kokorko-Grey-grey-200, #DEDEDE);
    background: #FFF;
    box-shadow: 0px 1.5px 4px -1px rgba(10, 9, 11, 0.07);
}

.input-field::placeholder {
    color: var(--Kokorko-Black-black-200, #8A8A8A);
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 475;
    line-height: 1.25rem; /* 142.857% */
    letter-spacing: -0.00313rem;
}

.form-inputs button {
    display: flex;
    height: 2.75rem;
    padding: 0rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    border: none;
    border-radius: 0.375rem;
    background: var(--Kokorko-Blue-Primary---500, #2752A5);
    color: var(--Global-Base-White, #FFF);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375rem; /* 137.5% */
    letter-spacing: -0.01125rem;
}

@media(min-width: 481) and (max-width: 768px) {
    .form-page {
        background-color: #ffffff;
        height: fit-content;
        width: 90%;
        gap: 4rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 3rem;
    }
    
    .header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        width: 100%;
        flex-wrap: wrap;
    }
    
    .kokoroko-logo {
        height: 42px !important;
        width: 124px !important;
    }
    
    .header-links {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 32px;
    }
    
    .text-wrapper-2 {
        color: var(--kokorkoblackblack-400);
        font-family: var(--semibold-16-font-family);
        font-size: var(--semibold-16-font-size);
        font-style: var(--semibold-16-font-style);
        font-weight: var(--semibold-16-font-weight);
        letter-spacing: var(--semibold-16-letter-spacing);
        line-height: var(--semibold-16-line-height);
        white-space: nowrap;
        width: fit-content;
    }
    
    .buttons-primary-2 {
        align-items: center;
        background-color: var(--kokorkoblueprimary-500);
        border-radius: 6px;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8px;
        justify-content: center;
        overflow: hidden;
        padding: 8px 12px;
        cursor: pointer;
    }
    
    .button-text-3 {
        color: var(--globalbasewhite);
        font-family: var(--medium-12-font-family);
        font-size: var(--medium-12-font-size);
        font-style: var(--medium-12-font-style);
        font-weight: var(--medium-12-font-weight);
        letter-spacing: var(--medium-12-letter-spacing);
        line-height: var(--medium-12-line-height);
        margin-top: -1px;
        white-space: nowrap;
        width: fit-content;
    }
    
    .form-container {
        width: 80%;
        display: flex;
        align-items: flex-start;
        gap: 3.5rem;
        margin: 0 auto;
        flex-wrap: wrap;
    }
    
    .form-text {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
    }
    
    .form-title {
        align-self: stretch;
        color: var(--Kokorko-Black-black-400, #222);
        font-family: Inter;
        font-size: 3rem;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 3.6rem */
        letter-spacing: -0.09rem;
        text-align: center;
    }
    
    .form-subtitle {
        align-self: stretch;
        color: var(--Kokorko-Black-black-300, #545454);
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 475;
        line-height: 1.375rem; /* 137.5% */
        letter-spacing: -0.01125rem;
        text-align: center;
    }
    
    .form-inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 3.5rem;
        border-radius: 1rem;
        border: 1px solid #EBEBEB;
        background: var(--Kokorko-White, #FAFAFA);
    }
    
    .form-inputs form {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
        align-self: stretch;
        margin: 0 auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
        /* border: 1px solid red; */
    }
    
    .input-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }
    
    .input-label {
        align-self: stretch;
        color: var(--Kokorko-Black-black-300, #545454);
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 475;
        line-height: 1.25rem; /* 142.857% */
        letter-spacing: -0.00313rem;
    }
    
    .input-field {
        display: flex;
        width: 100% !important;
        height: 3.125rem;
        padding: 0.1875rem 0.5rem 0.1875rem 0.75rem;
        align-items: center;
        gap: 0.5rem;
        align-self: stretch;
        border-radius: 0.5rem;
        border: 1px solid var(--Kokorko-Grey-grey-200, #DEDEDE);
        background: #FFF;
        box-shadow: 0px 1.5px 4px -1px rgba(10, 9, 11, 0.07);
    }
    
    .input-field::placeholder {
        color: var(--Kokorko-Black-black-200, #8A8A8A);
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 475;
        line-height: 1.25rem; /* 142.857% */
        letter-spacing: -0.00313rem;
    }
    
    .form-inputs button {
        display: flex;
        height: 2.75rem;
        padding: 0rem 1rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        align-self: stretch;
        border: none;
        border-radius: 0.375rem;
        background: var(--Kokorko-Blue-Primary---500, #2752A5);
        color: var(--Global-Base-White, #FFF);
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.375rem; /* 137.5% */
        letter-spacing: -0.01125rem;
    }
}

@media screen and (min-width: 769px) {
    .form-page {
        background-color: #ffffff;
        height: fit-content;
        width: 90%;
        gap: 4rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 3rem;
        margin-top: 2rem;
    }
    
    .header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        width: 100%;
        flex-wrap: wrap;
    }
    
    .kokoroko-logo {
        height: 42px !important;
        width: 124px !important;
    }
    
    .header-links {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 32px;
    }
    
    .text-wrapper-2 {
        color: var(--kokorkoblackblack-400);
        font-family: var(--semibold-16-font-family);
        font-size: var(--semibold-16-font-size);
        font-style: var(--semibold-16-font-style);
        font-weight: var(--semibold-16-font-weight);
        letter-spacing: var(--semibold-16-letter-spacing);
        line-height: var(--semibold-16-line-height);
        white-space: nowrap;
        width: fit-content;
    }
    
    .buttons-primary-2 {
        align-items: center;
        background-color: var(--kokorkoblueprimary-500);
        border-radius: 6px;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8px;
        justify-content: center;
        overflow: hidden;
        padding: 8px 12px;
        cursor: pointer;
    }
    
    .button-text-3 {
        color: var(--globalbasewhite);
        font-family: var(--medium-12-font-family);
        font-size: var(--medium-12-font-size);
        font-style: var(--medium-12-font-style);
        font-weight: var(--medium-12-font-weight);
        letter-spacing: var(--medium-12-letter-spacing);
        line-height: var(--medium-12-line-height);
        margin-top: -1px;
        white-space: nowrap;
        width: fit-content;
    }
    
    .form-container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 3.5rem;
        margin: 0 auto;
    }
    
    .form-text {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
    }
    
    .form-title {
        align-self: stretch;
        color: var(--Kokorko-Black-black-400, #222);
        font-family: Inter;
        font-size: 3rem;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 3.6rem */
        letter-spacing: -0.09rem;
    }
    
    .form-subtitle {
        align-self: stretch;
        color: var(--Kokorko-Black-black-300, #545454);
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 475;
        line-height: 1.375rem; /* 137.5% */
        letter-spacing: -0.01125rem;
    }
    
    .form-inputs {
        width: 52%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 3.5rem;
        border-radius: 1rem;
        border: 1px solid #EBEBEB;
        background: var(--Kokorko-White, #FAFAFA);
    }
    
    .form-inputs form {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
        align-self: stretch;
        margin: 0 auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
        /* border: 1px solid red; */
    }
    
    .input-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }
    
    .input-label {
        align-self: stretch;
        color: var(--Kokorko-Black-black-300, #545454);
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 475;
        line-height: 1.25rem; /* 142.857% */
        letter-spacing: -0.00313rem;
    }
    
    .input-field {
        display: flex;
        width: 100% !important;
        height: 3.125rem;
        padding: 0.1875rem 0.5rem 0.1875rem 0.75rem;
        align-items: center;
        gap: 0.5rem;
        align-self: stretch;
        border-radius: 0.5rem;
        border: 1px solid var(--Kokorko-Grey-grey-200, #DEDEDE);
        background: #FFF;
        box-shadow: 0px 1.5px 4px -1px rgba(10, 9, 11, 0.07);
    }

    input {
        padding: 0.5rem;
        border: none;
    }
    
    .input-field::placeholder {
        color: var(--Kokorko-Black-black-200, #8A8A8A);
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 475;
        line-height: 1.25rem; /* 142.857% */
        letter-spacing: -0.00313rem;
    }
    
    .form-inputs button {
        display: flex;
        height: 2.75rem;
        padding: 0rem 1rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        align-self: stretch;
        border: none;
        border-radius: 0.375rem;
        background: var(--Kokorko-Blue-Primary---500, #2752A5);
        color: var(--Global-Base-White, #FFF);
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.375rem; /* 137.5% */
        letter-spacing: -0.01125rem;
        cursor: pointer;
    }
}