.form-page {
  background-color: #fff;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
  width: 85%;
  height: fit-content;
  margin: 0 auto;
  padding-bottom: 3rem;
  display: flex;
}

.header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  display: flex;
}

.kokoroko-logo {
  width: 124px !important;
  height: 42px !important;
}

.header-links {
  flex: none;
  align-items: center;
  gap: 32px;
  display: inline-flex;
}

.text-wrapper-2 {
  color: var(--kokorkoblackblack-400);
  font-family: var(--semibold-16-font-family);
  font-size: var(--semibold-16-font-size);
  font-style: var(--semibold-16-font-style);
  font-weight: var(--semibold-16-font-weight);
  letter-spacing: var(--semibold-16-letter-spacing);
  line-height: var(--semibold-16-line-height);
  white-space: nowrap;
  width: fit-content;
}

.buttons-primary-2 {
  background-color: var(--kokorkoblueprimary-500);
  cursor: pointer;
  border-radius: 6px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  display: none;
  overflow: hidden;
}

.button-text-3 {
  color: var(--globalbasewhite);
  font-family: var(--medium-12-font-family);
  font-size: var(--medium-12-font-size);
  font-style: var(--medium-12-font-style);
  font-weight: var(--medium-12-font-weight);
  letter-spacing: var(--medium-12-letter-spacing);
  line-height: var(--medium-12-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
}

.form-container {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 3.5rem;
  width: 100%;
  height: fit-content;
  margin: 0 auto;
  display: flex;
}

.form-text {
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
  display: flex;
}

.form-title {
  color: var(--Kokorko-Black-black-400, #222);
  letter-spacing: -.09rem;
  align-self: stretch;
  font-family: Inter;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.form-subtitle {
  color: var(--Kokorko-Black-black-300, #545454);
  letter-spacing: -.01125rem;
  align-self: stretch;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 475;
  line-height: 1.375rem;
}

.form-inputs {
  background: var(--Kokorko-White, #fafafa);
  border: 1px solid #ebebeb;
  border-radius: 1rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 3.5rem;
  width: 100%;
  display: flex;
}

.form-inputs form {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 2rem;
  width: 90%;
  margin: 2rem auto;
  display: flex;
}

.input-wrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: .5rem;
  width: 100%;
  display: flex;
}

.input-label {
  color: var(--Kokorko-Black-black-300, #545454);
  letter-spacing: -.00313rem;
  align-self: stretch;
  font-family: Inter;
  font-size: .875rem;
  font-style: normal;
  font-weight: 475;
  line-height: 1.25rem;
}

.input-field {
  border: 1px solid var(--Kokorko-Grey-grey-200, #dedede);
  background: #fff;
  border-radius: .5rem;
  align-self: stretch;
  align-items: center;
  gap: .5rem;
  height: 3.125rem;
  padding: .1875rem .5rem .1875rem .75rem;
  display: flex;
  box-shadow: 0 1.5px 4px -1px #0a090b12;
  width: 100% !important;
}

.input-field::placeholder {
  color: var(--Kokorko-Black-black-200, #8a8a8a);
  letter-spacing: -.00313rem;
  font-family: Inter;
  font-size: .875rem;
  font-style: normal;
  font-weight: 475;
  line-height: 1.25rem;
}

.form-inputs button {
  background: var(--Kokorko-Blue-Primary---500, #2752a5);
  color: var(--Global-Base-White, #fff);
  letter-spacing: -.01125rem;
  border: none;
  border-radius: .375rem;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: .5rem;
  height: 2.75rem;
  padding: 0 1rem;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
  display: flex;
}

@media (width >= 481px) and (width <= 768px) {
  .form-page {
    background-color: #fff;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 4rem;
    width: 90%;
    height: fit-content;
    margin: 0 auto;
    padding-bottom: 3rem;
    display: flex;
  }

  .header {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    display: flex;
  }

  .kokoroko-logo {
    width: 124px !important;
    height: 42px !important;
  }

  .header-links {
    flex: none;
    align-items: center;
    gap: 32px;
    display: inline-flex;
  }

  .text-wrapper-2 {
    color: var(--kokorkoblackblack-400);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    white-space: nowrap;
    width: fit-content;
  }

  .buttons-primary-2 {
    background-color: var(--kokorkoblueprimary-500);
    cursor: pointer;
    border-radius: 6px;
    flex: none;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    display: inline-flex;
    overflow: hidden;
  }

  .button-text-3 {
    color: var(--globalbasewhite);
    font-family: var(--medium-12-font-family);
    font-size: var(--medium-12-font-size);
    font-style: var(--medium-12-font-style);
    font-weight: var(--medium-12-font-weight);
    letter-spacing: var(--medium-12-letter-spacing);
    line-height: var(--medium-12-line-height);
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
  }

  .form-container {
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 3.5rem;
    width: 80%;
    margin: 0 auto;
    display: flex;
  }

  .form-text {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    width: 100%;
    display: flex;
  }

  .form-title {
    color: var(--Kokorko-Black-black-400, #222);
    letter-spacing: -.09rem;
    text-align: center;
    align-self: stretch;
    font-family: Inter;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  .form-subtitle {
    color: var(--Kokorko-Black-black-300, #545454);
    letter-spacing: -.01125rem;
    text-align: center;
    align-self: stretch;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 475;
    line-height: 1.375rem;
  }

  .form-inputs {
    background: var(--Kokorko-White, #fafafa);
    border: 1px solid #ebebeb;
    border-radius: 1rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 3.5rem;
    width: 100%;
    display: flex;
  }

  .form-inputs form {
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 2rem;
    width: 90%;
    margin: 2rem auto;
    display: flex;
  }

  .input-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
    width: 100%;
    display: flex;
  }

  .input-label {
    color: var(--Kokorko-Black-black-300, #545454);
    letter-spacing: -.00313rem;
    align-self: stretch;
    font-family: Inter;
    font-size: .875rem;
    font-style: normal;
    font-weight: 475;
    line-height: 1.25rem;
  }

  .input-field {
    border: 1px solid var(--Kokorko-Grey-grey-200, #dedede);
    background: #fff;
    border-radius: .5rem;
    align-self: stretch;
    align-items: center;
    gap: .5rem;
    height: 3.125rem;
    padding: .1875rem .5rem .1875rem .75rem;
    display: flex;
    box-shadow: 0 1.5px 4px -1px #0a090b12;
    width: 100% !important;
  }

  .input-field::placeholder {
    color: var(--Kokorko-Black-black-200, #8a8a8a);
    letter-spacing: -.00313rem;
    font-family: Inter;
    font-size: .875rem;
    font-style: normal;
    font-weight: 475;
    line-height: 1.25rem;
  }

  .form-inputs button {
    background: var(--Kokorko-Blue-Primary---500, #2752a5);
    color: var(--Global-Base-White, #fff);
    letter-spacing: -.01125rem;
    border: none;
    border-radius: .375rem;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    gap: .5rem;
    height: 2.75rem;
    padding: 0 1rem;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375rem;
    display: flex;
  }
}

@media screen and (width >= 769px) {
  .form-page {
    background-color: #fff;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 4rem;
    width: 90%;
    height: fit-content;
    margin: 2rem auto 0;
    padding-bottom: 3rem;
    display: flex;
  }

  .header {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    display: flex;
  }

  .kokoroko-logo {
    width: 124px !important;
    height: 42px !important;
  }

  .header-links {
    flex: none;
    align-items: center;
    gap: 32px;
    display: inline-flex;
  }

  .text-wrapper-2 {
    color: var(--kokorkoblackblack-400);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    white-space: nowrap;
    width: fit-content;
  }

  .buttons-primary-2 {
    background-color: var(--kokorkoblueprimary-500);
    cursor: pointer;
    border-radius: 6px;
    flex: none;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    display: inline-flex;
    overflow: hidden;
  }

  .button-text-3 {
    color: var(--globalbasewhite);
    font-family: var(--medium-12-font-family);
    font-size: var(--medium-12-font-size);
    font-style: var(--medium-12-font-style);
    font-weight: var(--medium-12-font-weight);
    letter-spacing: var(--medium-12-letter-spacing);
    line-height: var(--medium-12-line-height);
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
  }

  .form-container {
    align-items: flex-start;
    gap: 3.5rem;
    width: 100%;
    margin: 0 auto;
    display: flex;
  }

  .form-text {
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    width: 40%;
    display: flex;
  }

  .form-title {
    color: var(--Kokorko-Black-black-400, #222);
    letter-spacing: -.09rem;
    align-self: stretch;
    font-family: Inter;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  .form-subtitle {
    color: var(--Kokorko-Black-black-300, #545454);
    letter-spacing: -.01125rem;
    align-self: stretch;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 475;
    line-height: 1.375rem;
  }

  .form-inputs {
    background: var(--Kokorko-White, #fafafa);
    border: 1px solid #ebebeb;
    border-radius: 1rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 3.5rem;
    width: 52%;
    display: flex;
  }

  .form-inputs form {
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 2rem;
    width: 90%;
    margin: 2rem auto;
    display: flex;
  }

  .input-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
    width: 100%;
    display: flex;
  }

  .input-label {
    color: var(--Kokorko-Black-black-300, #545454);
    letter-spacing: -.00313rem;
    align-self: stretch;
    font-family: Inter;
    font-size: .875rem;
    font-style: normal;
    font-weight: 475;
    line-height: 1.25rem;
  }

  .input-field {
    border: 1px solid var(--Kokorko-Grey-grey-200, #dedede);
    background: #fff;
    border-radius: .5rem;
    align-self: stretch;
    align-items: center;
    gap: .5rem;
    height: 3.125rem;
    padding: .1875rem .5rem .1875rem .75rem;
    display: flex;
    box-shadow: 0 1.5px 4px -1px #0a090b12;
    width: 100% !important;
  }

  input {
    border: none;
    padding: .5rem;
  }

  .input-field::placeholder {
    color: var(--Kokorko-Black-black-200, #8a8a8a);
    letter-spacing: -.00313rem;
    font-family: Inter;
    font-size: .875rem;
    font-style: normal;
    font-weight: 475;
    line-height: 1.25rem;
  }

  .form-inputs button {
    background: var(--Kokorko-Blue-Primary---500, #2752a5);
    color: var(--Global-Base-White, #fff);
    letter-spacing: -.01125rem;
    cursor: pointer;
    border: none;
    border-radius: .375rem;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    gap: .5rem;
    height: 2.75rem;
    padding: 0 1rem;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375rem;
    display: flex;
  }
}

:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: .8;
  --PhoneInputInternationalIconGlobe-opacity: .65;
  --PhoneInputCountrySelect-marginRight: .35em;
  --PhoneInputCountrySelectArrow-width: .3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: .45;
  --PhoneInputCountrySelectArrow-color: currentColor;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: #00000080;
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: #0000001a;
}

.PhoneInput {
  align-items: center;
  display: flex;
}

.PhoneInputInput {
  flex: 1;
  min-width: 0;
}

.PhoneInputCountryIcon {
  width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  width: 100%;
  height: 100%;
  display: block;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

.PhoneInputCountry {
  margin-right: var(--PhoneInputCountrySelect-marginRight);
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
}

.PhoneInputCountrySelect {
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.PhoneInputCountrySelect[disabled], .PhoneInputCountrySelect[readonly] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  content: "";
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
  display: block;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.terms-condition {
  width: 100%;
}

.terms-container {
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  width: 90%;
  max-width: 80%;
  height: fit-content;
  margin: 0 auto 3rem;
  padding: 20px 0;
  display: flex;
}

.terms-condition .footer-container {
  background: linear-gradient(#0a2049 0%, #3864b8 100%);
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  margin: 0 auto;
  padding: 32px 0;
  display: flex;
}

.terms-condition .footer-elements-container {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 88%;
  display: flex;
}

.terms-condition .footer-elements {
  flex: none !important;
}

.terms-condition .footer-section-elements {
  color: var(--globalbasewhite) !important;
}

@media screen and (width >= 768px) {
  .terms-container {
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    width: 60%;
    max-width: 80%;
    height: fit-content;
    margin: 0 auto 3rem;
    padding: 20px;
    display: flex;
  }

  .terms-condition .footer-container {
    background: linear-gradient(#0a2049 0%, #3864b8 100%);
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
    padding: 32px 0;
    display: flex;
  }

  .terms-condition .footer-elements-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 88%;
    display: flex;
  }
}

.terms-container h1 {
  color: var(--kokorkoblackblack-400);
  letter-spacing: -.18px;
  text-align: center;
  font-family: Inter, Helvetica;
  font-size: 2rem;
  font-weight: 600;
  line-height: 32px;
}

.terms-content {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.terms-content h2 {
  color: var(--kokorkoblackblack-400);
  letter-spacing: -.18px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.terms-content p {
  color: var(--kokorkoblackblack-400);
  letter-spacing: -.18px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.terms-content ul {
  flex-direction: column;
  gap: .5rem;
  margin-left: 2rem;
  display: flex;
}

.footer-section {
  align-items: flex-start;
}

.footer-section .item-text-input {
  box-shadow: var(--shadow-hard-small);
  flex-direction: column;
  align-items: center;
  gap: 5px;
  display: flex;
}

.footer-section .frame {
  background-color: var(--globalbasewhite);
  border: 1px solid;
  border-color: var(--global-neutral-grey-500);
  box-shadow: var(--shadow-soft-2x-small);
  border-radius: 8px;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 40px;
  padding: 0 12px;
  display: flex;
  overflow: hidden;
}

.footer-section .container {
  flex: 1;
  align-items: center;
  gap: 4px;
  display: flex;
}

.footer-section .texthead-title {
  color: var(--global-neutral-grey-900);
  font-family: var(--regular-14-font-family);
  font-size: var(--regular-14-font-size);
  font-style: var(--regular-14-font-style);
  font-weight: var(--regular-14-font-weight);
  letter-spacing: var(--regular-14-letter-spacing);
  line-height: var(--regular-14-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
}

.footer-section .buttons-primary {
  all: unset;
  background-color: var(--globalblue-900);
  box-shadow: var(--shadow-hard-small);
  box-sizing: border-box;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 40px;
  padding: 0 14px;
}

.footer-section .button-text {
  all: unset;
  box-sizing: border-box;
  color: var(--global-base-white);
  font-family: var(--semibold-14-font-family);
  font-size: var(--semibold-14-font-size);
  font-style: var(--semibold-14-font-style);
  font-weight: var(--semibold-14-font-weight);
  letter-spacing: var(--semibold-14-letter-spacing);
  line-height: var(--semibold-14-line-height);
  white-space: nowrap;
  width: fit-content;
}

.footer-section .footer-section-link {
  align-items: center;
  gap: 8px;
  height: 24px;
  display: inline-flex;
}

.footer-section .link {
  color: var(--global-neutral-grey-1100);
  font-family: var(--medium-14-font-family);
  font-size: var(--medium-14-font-size);
  font-style: var(--medium-14-font-style);
  font-weight: var(--medium-14-font-weight);
  letter-spacing: var(--medium-14-letter-spacing);
  line-height: var(--medium-14-line-height);
  white-space: nowrap;
  width: fit-content;
}

.footer-section .link-wrapper, .footer-section .div-wrapper, .footer-section .div, .footer-section .footer-section-link-2, .footer-section .footer-section-link-3 {
  align-items: center;
  gap: 8px;
  height: 24px;
  display: inline-flex;
}

.footer-section .subhead-title {
  color: var(--global-neutral-grey-1000);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
}

.footer-section .misc-icons-social {
  width: 16px !important;
  height: 16px !important;
}

.footer-section .text-wrapper {
  color: var(--global-neutral-grey-1000);
  font-family: var(--medium-14-font-family);
  font-size: var(--medium-14-font-size);
  font-style: var(--medium-14-font-style);
  font-weight: var(--medium-14-font-weight);
  letter-spacing: var(--medium-14-letter-spacing);
  line-height: var(--medium-14-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
}

.footer-section .header-nav-bar-logo {
  flex: none !important;
  margin-top: -1.5px !important;
  margin-left: -2.5px !important;
}

.footer-section .p {
  color: var(--global-neutral-grey-1000);
  font-family: var(--regular-14-font-family);
  font-size: var(--regular-14-font-size);
  font-style: var(--regular-14-font-style);
  font-weight: var(--regular-14-font-weight);
  letter-spacing: var(--regular-14-letter-spacing);
  line-height: var(--regular-14-line-height);
  width: 25%;
}

.footer-section.type-subscribe {
  gap: 12px;
  width: 26%;
  display: flex;
}

.footer-section.type-subscribe-stacked {
  flex-direction: column;
  gap: 12px;
  width: 26%;
  display: flex;
}

.footer-section.type-basic-nav {
  gap: 24px;
  display: inline-flex;
}

.footer-section.type-basic-nav-vertical {
  flex-direction: column;
  gap: 20px;
  display: inline-flex;
}

.footer-section.type-text {
  flex-direction: column;
  gap: 24px;
  display: inline-flex;
}

.footer-section.type-footer-section {
  gap: 24px;
  display: inline-flex;
}

.footer-section.type-logo {
  flex-direction: column;
  gap: 24px;
  display: inline-flex;
}

.footer-section.type-copyright, .footer-section.type-socials {
  gap: 24px;
  display: inline-flex;
}

.footer-section.type-subscribe .item-text-input {
  flex: 1;
}

.footer-section.type-subscribe-stacked .item-text-input {
  flex: none;
  align-self: stretch;
  width: 100%;
}

.footer-section.type-basic-nav .item-text-input, .footer-section.type-basic-nav-vertical .item-text-input, .footer-section.type-text .item-text-input, .footer-section.type-footer-section .item-text-input, .footer-section.type-logo .item-text-input, .footer-section.type-copyright .item-text-input, .footer-section.type-socials .item-text-input {
  flex: 1;
}

.footer-section.type-subscribe .buttons-primary {
  flex: none;
  display: inline-flex;
}

.footer-section.type-subscribe-stacked .buttons-primary {
  align-self: stretch;
  width: 100%;
  display: flex;
}

.footer-section.type-basic-nav .buttons-primary, .footer-section.type-basic-nav-vertical .buttons-primary, .footer-section.type-text .buttons-primary, .footer-section.type-footer-section .buttons-primary, .footer-section.type-logo .buttons-primary, .footer-section.type-copyright .buttons-primary, .footer-section.type-socials .buttons-primary {
  display: inline-flex;
}

.footer-section.type-basic-nav .footer-section-link, .footer-section.type-basic-nav .link-wrapper, .footer-section.type-basic-nav .div-wrapper, .footer-section.type-basic-nav .div, .footer-section.type-basic-nav .footer-section-link-2, .footer-section.type-basic-nav .footer-section-link-3 {
  flex: none;
}

.footer-section.type-subscribe .subhead-title, .footer-section.type-subscribe-stacked .subhead-title, .footer-section.type-basic-nav .subhead-title, .footer-section.type-basic-nav-vertical .subhead-title, .footer-section.type-text .subhead-title, .footer-section.type-footer-section .subhead-title, .footer-section.type-logo .subhead-title {
  font-family: var(--medium-14-font-family);
  font-size: var(--medium-14-font-size);
  font-style: var(--medium-14-font-style);
  font-weight: var(--medium-14-font-weight);
  letter-spacing: var(--medium-14-letter-spacing);
  line-height: var(--medium-14-line-height);
}

.footer-section.type-copyright .subhead-title {
  font-family: var(--regular-14-font-family);
  font-size: var(--regular-14-font-size);
  font-style: var(--regular-14-font-style);
  font-weight: var(--regular-14-font-weight);
  letter-spacing: var(--regular-14-letter-spacing);
  line-height: var(--regular-14-line-height);
}

.footer-section.type-socials .subhead-title {
  font-family: var(--medium-14-font-family);
  font-size: var(--medium-14-font-size);
  font-style: var(--medium-14-font-style);
  font-weight: var(--medium-14-font-weight);
  letter-spacing: var(--medium-14-letter-spacing);
  line-height: var(--medium-14-line-height);
}

.footer-section.type-text .p {
  margin-top: -1px;
}

.privacy-policy {
  width: 100%;
}

.privacy-policy-container {
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  width: 90%;
  max-width: 80%;
  height: fit-content;
  margin: 0 auto 3rem;
  padding: 20px 0;
  display: flex;
}

.privacy-policy .footer-container {
  background: linear-gradient(#0a2049 0%, #3864b8 100%);
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  margin: 0 auto;
  padding: 32px 0;
  display: flex;
}

.privacy-policy .footer-elements-container {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 88%;
  display: flex;
}

.privacy-policy .footer-elements {
  flex: none !important;
}

.privacy-policy .footer-section-elements {
  color: var(--globalbasewhite) !important;
}

@media screen and (width >= 768px) {
  .privacy-policy-container {
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    width: 60%;
    max-width: 80%;
    height: fit-content;
    margin: 0 auto 3rem;
    padding: 20px;
    display: flex;
  }

  .privacy-policy .footer-container {
    background: linear-gradient(#0a2049 0%, #3864b8 100%);
    flex-direction: row;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
    margin: 0 auto;
    padding: 32px 0;
    display: flex;
  }

  .privacy-policy .footer-elements-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 88%;
    display: flex;
  }
}

.privacy-policy-container h1 {
  color: var(--kokorkoblackblack-400);
  letter-spacing: -.18px;
  text-align: center;
  font-family: Inter, Helvetica;
  font-size: 2rem;
  font-weight: 600;
  line-height: 32px;
}

.privacy-policy-content {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.privacy-policy-content h2 {
  color: var(--kokorkoblackblack-400);
  letter-spacing: -.18px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.privacy-policy-content p {
  color: var(--kokorkoblackblack-400);
  letter-spacing: -.18px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.privacy-policy-content ul {
  flex-direction: column;
  gap: .5rem;
  margin-left: 2rem;
  display: flex;
}

li {
  list-style-type: disc;
  list-style-position: inside;
}

hr {
  margin: 20px 0;
}

html {
  scroll-behavior: smooth;
}

.header-section {
  background-color: #fff;
  width: 100%;
}

.header-section .header-section-container {
  justify-content: space-between;
  align-items: center;
  width: 88%;
  margin: 0 auto;
  padding: 1.8rem 0;
  display: flex;
}

.header-section .header-section-buttons {
  align-items: center;
  gap: 1.2rem;
  display: flex;
}

@media screen and (width <= 527px) {
  .desktop-coming-soon .div-2 {
    background-color: #fff;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 88%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
  }

  .button-text-wrapper {
    background-color: var(--kokorkoblueprimary-500);
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 44px;
    padding: 0 16px;
    display: inline-flex;
    overflow: hidden;
  }

  .desktop-coming-soon .button-text-2 {
    color: var(--globalbasewhite);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    white-space: nowrap;
    width: fit-content;
  }

  .desktop-coming-soon .frame-2 {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    display: flex;
  }

  .desktop-coming-soon .kokoroko-logo {
    width: 124px !important;
    height: 42px !important;
  }

  .desktop-coming-soon .frame-3 {
    flex: none;
    align-items: center;
    gap: 32px;
    display: inline-flex;
  }

  .desktop-coming-soon .text-wrapper-2 {
    color: var(--kokorkoblackblack-400);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    white-space: nowrap;
    width: fit-content;
  }

  .desktop-coming-soon .buttons-primary-2 {
    background-color: var(--kokorkoblueprimary-500);
    cursor: pointer;
    border-radius: 6px;
    flex: none;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    display: none;
    overflow: hidden;
  }

  .desktop-coming-soon .button-text-3 {
    color: var(--globalbasewhite);
    font-family: var(--medium-12-font-family);
    font-size: var(--medium-12-font-size);
    font-style: var(--medium-12-font-style);
    font-weight: var(--medium-12-font-weight);
    letter-spacing: var(--medium-12-letter-spacing);
    line-height: var(--medium-12-line-height);
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
  }

  .desktop-coming-soon .frame-4 {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    width: 90%;
    margin-top: 5rem;
    display: flex;
  }

  .desktop-coming-soon .frame-5 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 16px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-6 {
    flex: none;
    align-items: center;
    gap: 8px;
    display: inline-flex;
  }

  .desktop-coming-soon .icon-park-outline {
    width: 40px;
    height: 40px;
  }

  .desktop-coming-soon .frame-7 {
    flex: none;
    align-items: center;
    gap: 4px;
    display: inline-flex;
  }

  .desktop-coming-soon .text-wrapper-3 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.18px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }

  .desktop-coming-soon .text-wrapper-4 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -1.44px;
    text-align: center;
    align-self: stretch;
    width: 100%;
    font-family: Inter, Helvetica;
    font-size: 42px;
    font-weight: 600;
    line-height: 57.6px;
    text-wrap: wrap !important;
  }

  .desktop-coming-soon .text-wrapper-5 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.18px;
    text-align: center;
    align-self: stretch;
    font-family: Inter, Helvetica;
    font-size: 1rem;
    font-weight: 400;
    line-height: 22px;
  }

  .desktop-coming-soon .overlap {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50dvh;
    display: flex;
  }

  .group {
    width: 20% !important;
    height: 10% !important;
    transform: rotate(180.42deg) !important;
  }

  .desktop-coming-soon .overlap-group {
    flex-direction: column;
    height: 100%;
    display: flex;
  }

  .desktop-coming-soon .ellipse {
    background-color: #ff3405;
    border-radius: 166px;
    width: 50%;
    height: 25%;
    transform: rotate(178.29deg);
    box-shadow: 125px -191.68px 212.98px 95.22px #ff340578;
  }

  .desktop-coming-soon .ellipse-2 {
    background-color: #2752a5;
    border-radius: 166px;
    align-self: flex-end;
    width: 100%;
    height: 100%;
    transform: rotate(178.29deg);
    box-shadow: -224px 191.68px 212.98px 95.22px #2752a58a;
  }

  .desktop-coming-soon .mock-up-wrapper {
    z-index: 1;
    width: 52%;
    height: 53%;
    position: absolute !important;
    top: 40rem !important;
    left: 3rem !important;
  }

  .desktop-coming-soon .mock-up {
    height: auto;
  }

  .desktop-coming-soon .studio-display-front {
    object-fit: cover;
    border: .2rem solid #000;
    border-radius: .2rem;
    width: 100%;
    height: 50%;
    box-sizing: border-box !important;
  }

  .desktop-coming-soon .img {
    z-index: 2;
    width: 60%;
    height: auto;
    position: absolute;
    top: 42rem;
    right: 1.1rem;
  }

  .desktop-coming-soon .frame-8 {
    flex-direction: column;
    align-items: flex-start;
    gap: 178px;
    width: 100%;
    margin-top: 6rem;
    display: flex;
  }

  .desktop-coming-soon .frame-9 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-end;
    gap: 48px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-10 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-11 {
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 32px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .vector {
    width: 71.5px;
    height: 84px;
  }

  .desktop-coming-soon .text-wrapper-6 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: 0;
    flex: 1;
    font-family: Inter, Helvetica;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 41.6px;
  }

  .desktop-coming-soon .text-wrapper-7 {
    color: var(--kokorkoblackblack-300);
    letter-spacing: -.18px;
    align-self: stretch;
    font-family: Inter, Helvetica;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 30px;
  }

  .desktop-coming-soon .frame-12 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 24px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-wrapper {
    background-color: #f1f5fb;
    border-radius: 12px;
    flex: 1;
    justify-content: center;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    padding: 2rem 1.5rem;
    display: flex;
  }

  .desktop-coming-soon .frame-13 {
    flex-direction: column;
    flex: 1;
    align-items: center;
    gap: 24px;
    display: flex;
  }

  .desktop-coming-soon .text-wrapper-8 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.2px;
    align-self: stretch;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }

  .desktop-coming-soon .text-wrapper-9 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.18px;
    align-self: stretch;
    font-family: Inter, Helvetica;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }

  .desktop-coming-soon .frame-14 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 48px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .group-2 {
    width: 74.59px;
    height: 68.5px;
    margin-top: -1.75px;
    margin-bottom: -1.75px;
    margin-left: -1.75px;
  }

  .desktop-coming-soon .text-wrapper-10 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: 0;
    width: fit-content;
    font-family: Inter, Helvetica;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 41.6px;
  }

  .desktop-coming-soon .text-wrapper-11 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.18px;
    align-self: stretch;
    font-family: Inter, Helvetica;
    font-size: 1rem;
    font-weight: 500;
    line-height: 30px;
  }

  .desktop-coming-soon .frame-15 {
    background-color: #f1f5fc;
    border-radius: 12px;
    flex: 1;
    justify-content: center;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    padding: 40px 32px 72px;
    display: flex;
  }

  .desktop-coming-soon .frame-16 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .shopping-cart {
    width: 76px !important;
    height: 76px !important;
  }

  .desktop-coming-soon .frame-17 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 24px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-18 {
    background-color: #f1f5fc;
    border-radius: 12px;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 40px 32px 72px;
    display: flex;
  }

  .desktop-coming-soon .about-us {
    background: linear-gradient(#0a2049 0%, #3864b8 100%);
    flex-direction: column;
    align-items: center;
    gap: 72px;
    width: 100%;
    height: fit-content;
    margin-top: 10rem;
    padding: 3rem 1.5rem;
    display: flex;
    overflow: hidden;
  }

  .desktop-coming-soon .frame-19 {
    flex-direction: column;
    flex: none;
    align-items: center;
    gap: 32px;
    display: inline-flex;
  }

  .desktop-coming-soon .text-wrapper-12 {
    color: #fff;
    letter-spacing: -.8px;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 2rem;
    font-weight: 600;
    line-height: 60px;
  }

  .desktop-coming-soon .at-kokorko-we {
    color: #fff;
    letter-spacing: -.4px;
    text-align: center;
    font-family: Inter, Helvetica;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 30px;
  }

  .desktop-coming-soon .footer-bottom-bar {
    flex-direction: column;
    flex: none;
    justify-content: space-between;
    align-self: stretch;
    align-items: center;
    gap: 1rem;
    width: 100%;
    margin-bottom: -52px;
    padding: 32px 0;
    display: flex;
  }

  .desktop-coming-soon .footer-elements {
    flex: none !important;
  }

  .desktop-coming-soon .footer-section-elements {
    color: var(--globalbasewhite) !important;
  }
}

@media (width >= 528px) and (width <= 610px) {
  .desktop-coming-soon .div-2 {
    background-color: #fff;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 88%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
  }

  .button-text-wrapper {
    background-color: var(--kokorkoblueprimary-500);
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 44px;
    padding: 0 16px;
    display: inline-flex;
    overflow: hidden;
  }

  .desktop-coming-soon .button-text-2 {
    color: var(--globalbasewhite);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    white-space: nowrap;
    width: fit-content;
  }

  .desktop-coming-soon .frame-2 {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    display: flex;
  }

  .desktop-coming-soon .kokoroko-logo {
    width: 124px !important;
    height: 42px !important;
  }

  .desktop-coming-soon .frame-3 {
    flex: none;
    align-items: center;
    gap: 32px;
    display: inline-flex;
  }

  .desktop-coming-soon .text-wrapper-2 {
    color: var(--kokorkoblackblack-400);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    white-space: nowrap;
    width: fit-content;
  }

  .desktop-coming-soon .buttons-primary-2 {
    background-color: var(--kokorkoblueprimary-500);
    cursor: pointer;
    border-radius: 6px;
    flex: none;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    display: none;
    overflow: hidden;
  }

  .desktop-coming-soon .button-text-3 {
    color: var(--globalbasewhite);
    font-family: var(--medium-12-font-family);
    font-size: var(--medium-12-font-size);
    font-style: var(--medium-12-font-style);
    font-weight: var(--medium-12-font-weight);
    letter-spacing: var(--medium-12-letter-spacing);
    line-height: var(--medium-12-line-height);
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
  }

  .desktop-coming-soon .frame-4 {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    width: 90%;
    margin-top: 5rem;
    display: flex;
  }

  .desktop-coming-soon .frame-5 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 16px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-6 {
    flex: none;
    align-items: center;
    gap: 8px;
    display: inline-flex;
  }

  .desktop-coming-soon .icon-park-outline {
    width: 40px;
    height: 40px;
  }

  .desktop-coming-soon .frame-7 {
    flex: none;
    align-items: center;
    gap: 4px;
    display: inline-flex;
  }

  .desktop-coming-soon .text-wrapper-3 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.18px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }

  .desktop-coming-soon .text-wrapper-4 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -1.44px;
    text-align: center;
    align-self: stretch;
    width: 100%;
    font-family: Inter, Helvetica;
    font-size: 42px;
    font-weight: 600;
    line-height: 57.6px;
    text-wrap: wrap !important;
  }

  .desktop-coming-soon .text-wrapper-5 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.18px;
    text-align: center;
    align-self: stretch;
    font-family: Inter, Helvetica;
    font-size: 1rem;
    font-weight: 400;
    line-height: 22px;
  }

  .desktop-coming-soon .overlap {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50dvh;
    display: flex;
  }

  .group {
    width: 20% !important;
    height: 10% !important;
    transform: rotate(180.42deg) !important;
  }

  .desktop-coming-soon .overlap-group {
    flex-direction: column;
    height: 100%;
    display: flex;
  }

  .desktop-coming-soon .ellipse {
    background-color: #ff3405;
    border-radius: 166px;
    width: 50%;
    height: 25%;
    transform: rotate(178.29deg);
    box-shadow: 125px -191.68px 212.98px 95.22px #ff340578;
  }

  .desktop-coming-soon .ellipse-2 {
    background-color: #2752a5;
    border-radius: 166px;
    align-self: flex-end;
    width: 100%;
    height: 100%;
    transform: rotate(178.29deg);
    box-shadow: -224px 191.68px 212.98px 95.22px #2752a58a;
  }

  .desktop-coming-soon .mock-up-wrapper {
    z-index: 1;
    width: 52%;
    height: 53%;
    position: absolute !important;
    top: 35rem !important;
    left: 3rem !important;
  }

  .desktop-coming-soon .mock-up {
    height: auto;
  }

  .desktop-coming-soon .studio-display-front {
    object-fit: cover;
    border: .2rem solid #000;
    border-radius: .2rem;
    width: 100%;
    height: 50%;
    box-sizing: border-box !important;
  }

  .desktop-coming-soon .img {
    z-index: 2;
    width: 60%;
    height: auto;
    position: absolute;
    top: 38rem;
    right: 1.1rem;
  }

  .desktop-coming-soon .frame-8 {
    flex-direction: column;
    align-items: flex-start;
    gap: 178px;
    width: 100%;
    margin-top: 8rem;
    display: flex;
  }

  .desktop-coming-soon .frame-9 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-end;
    gap: 48px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-10 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-11 {
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 32px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .vector {
    width: 71.5px;
    height: 84px;
  }

  .desktop-coming-soon .text-wrapper-6 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: 0;
    flex: 1;
    font-family: Inter, Helvetica;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 41.6px;
  }

  .desktop-coming-soon .text-wrapper-7 {
    color: var(--kokorkoblackblack-300);
    letter-spacing: -.18px;
    align-self: stretch;
    font-family: Inter, Helvetica;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 30px;
  }

  .desktop-coming-soon .frame-12 {
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 24px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-wrapper {
    background-color: #f1f5fb;
    border-radius: 12px;
    flex: 1;
    justify-content: center;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    padding: 2rem 1.5rem;
    display: flex;
  }

  .desktop-coming-soon .frame-13 {
    flex-direction: column;
    flex: 1;
    align-items: center;
    gap: 24px;
    display: flex;
  }

  .desktop-coming-soon .text-wrapper-8 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.2px;
    align-self: stretch;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }

  .desktop-coming-soon .text-wrapper-9 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.18px;
    align-self: stretch;
    font-family: Inter, Helvetica;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }

  .desktop-coming-soon .frame-14 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 48px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .group-2 {
    width: 74.59px;
    height: 68.5px;
    margin-top: -1.75px;
    margin-bottom: -1.75px;
    margin-left: -1.75px;
  }

  .desktop-coming-soon .text-wrapper-10 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: 0;
    width: fit-content;
    font-family: Inter, Helvetica;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 41.6px;
  }

  .desktop-coming-soon .text-wrapper-11 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.18px;
    align-self: stretch;
    font-family: Inter, Helvetica;
    font-size: 1rem;
    font-weight: 500;
    line-height: 30px;
  }

  .desktop-coming-soon .frame-15 {
    background-color: #f1f5fc;
    border-radius: 12px;
    flex: 1;
    justify-content: center;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    padding: 40px 32px 72px;
    display: flex;
  }

  .desktop-coming-soon .frame-16 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .shopping-cart {
    width: 76px !important;
    height: 76px !important;
  }

  .desktop-coming-soon .frame-17 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 24px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-18 {
    background-color: #f1f5fc;
    border-radius: 12px;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 40px 32px 72px;
    display: flex;
  }

  .desktop-coming-soon .about-us {
    background: linear-gradient(#0a2049 0%, #3864b8 100%);
    flex-direction: column;
    align-items: center;
    gap: 72px;
    width: 100%;
    height: fit-content;
    margin-top: 10rem;
    padding: 3rem 1.5rem;
    display: flex;
    overflow: hidden;
  }

  .desktop-coming-soon .frame-19 {
    flex-direction: column;
    flex: none;
    align-items: center;
    gap: 32px;
    display: inline-flex;
  }

  .desktop-coming-soon .text-wrapper-12 {
    color: #fff;
    letter-spacing: -.8px;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 2rem;
    font-weight: 600;
    line-height: 60px;
  }

  .desktop-coming-soon .at-kokorko-we {
    color: #fff;
    letter-spacing: -.4px;
    text-align: center;
    font-family: Inter, Helvetica;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 30px;
  }

  .desktop-coming-soon .footer-bottom-bar {
    flex-direction: column;
    flex: none;
    justify-content: space-between;
    align-self: stretch;
    align-items: center;
    gap: 1rem;
    width: 100%;
    margin-bottom: -52px;
    padding: 32px 0;
    display: flex;
  }

  .desktop-coming-soon .footer-elements {
    flex: none !important;
  }

  .desktop-coming-soon .footer-section-elements {
    color: var(--globalbasewhite) !important;
  }
}

@media (width >= 611px) and (width <= 849px) {
  .desktop-coming-soon .div-2 {
    background-color: #fff;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 88%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
  }

  .button-text-wrapper {
    background-color: var(--kokorkoblueprimary-500);
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 44px;
    padding: 0 16px;
    display: inline-flex;
    overflow: hidden;
  }

  .desktop-coming-soon .button-text-2 {
    color: var(--globalbasewhite);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    white-space: nowrap;
    width: fit-content;
  }

  .desktop-coming-soon .frame-2 {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    display: flex;
  }

  .desktop-coming-soon .kokoroko-logo {
    width: 124px !important;
    height: 42px !important;
  }

  .desktop-coming-soon .frame-3 {
    flex: none;
    align-items: center;
    gap: 32px;
    display: inline-flex;
  }

  .desktop-coming-soon .text-wrapper-2 {
    color: var(--kokorkoblackblack-400);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    white-space: nowrap;
    width: fit-content;
  }

  .desktop-coming-soon .buttons-primary-2 {
    background-color: var(--kokorkoblueprimary-500);
    cursor: pointer;
    border-radius: 6px;
    flex: none;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    display: none;
    overflow: hidden;
  }

  .desktop-coming-soon .button-text-3 {
    color: var(--globalbasewhite);
    font-family: var(--medium-12-font-family);
    font-size: var(--medium-12-font-size);
    font-style: var(--medium-12-font-style);
    font-weight: var(--medium-12-font-weight);
    letter-spacing: var(--medium-12-letter-spacing);
    line-height: var(--medium-12-line-height);
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
  }

  .desktop-coming-soon .frame-4 {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    width: 90%;
    margin-top: 5rem;
    display: flex;
  }

  .desktop-coming-soon .frame-5 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 16px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-6 {
    flex: none;
    align-items: center;
    gap: 8px;
    display: inline-flex;
  }

  .desktop-coming-soon .icon-park-outline {
    width: 40px;
    height: 40px;
  }

  .desktop-coming-soon .frame-7 {
    flex: none;
    align-items: center;
    gap: 4px;
    display: inline-flex;
  }

  .desktop-coming-soon .text-wrapper-3 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.18px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }

  .desktop-coming-soon .text-wrapper-4 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -1.44px;
    text-align: center;
    align-self: stretch;
    width: 100%;
    font-family: Inter, Helvetica;
    font-size: 42px;
    font-weight: 600;
    line-height: 57.6px;
    text-wrap: wrap !important;
  }

  .desktop-coming-soon .text-wrapper-5 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.18px;
    text-align: center;
    align-self: stretch;
    font-family: Inter, Helvetica;
    font-size: 1rem;
    font-weight: 400;
    line-height: 22px;
  }

  .desktop-coming-soon .overlap {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 15rem;
    display: flex;
  }

  .group {
    width: 20% !important;
    height: 10% !important;
    transform: rotate(180.42deg) !important;
  }

  .desktop-coming-soon .overlap-group {
    flex-direction: column;
    height: 100%;
    display: flex;
  }

  .desktop-coming-soon .ellipse {
    background-color: #ff3405;
    border-radius: 166px;
    width: 50%;
    height: 25%;
    transform: rotate(178.29deg);
    box-shadow: 125px -191.68px 212.98px 95.22px #ff340578;
  }

  .desktop-coming-soon .ellipse-2 {
    background-color: #2752a5;
    border-radius: 166px;
    align-self: flex-end;
    width: 100%;
    height: 100%;
    transform: rotate(178.29deg);
    box-shadow: -224px 191.68px 212.98px 95.22px #2752a58a;
  }

  .desktop-coming-soon .mock-up-wrapper {
    z-index: 1;
    width: 52%;
    height: 53%;
    position: absolute !important;
    top: 40rem !important;
    left: 6rem !important;
  }

  .desktop-coming-soon .mock-up {
    height: auto;
  }

  .desktop-coming-soon .studio-display-front {
    object-fit: cover;
    border: .2rem solid #000;
    border-radius: .2rem;
    width: 100%;
    height: 50%;
    box-sizing: border-box !important;
  }

  .desktop-coming-soon .img {
    z-index: 2;
    width: 60%;
    height: auto;
    position: absolute;
    top: 45rem;
    right: 2rem;
  }

  .desktop-coming-soon .frame-8 {
    flex-direction: column;
    align-items: flex-start;
    gap: 178px;
    width: 100%;
    margin-top: 25rem;
    display: flex;
  }

  .desktop-coming-soon .frame-9 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-end;
    gap: 48px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-10 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-11 {
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 32px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .vector {
    width: 71.5px;
    height: 84px;
  }

  .desktop-coming-soon .text-wrapper-6 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: 0;
    flex: 1;
    font-family: Inter, Helvetica;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 41.6px;
  }

  .desktop-coming-soon .text-wrapper-7 {
    color: var(--kokorkoblackblack-300);
    letter-spacing: -.18px;
    align-self: stretch;
    font-family: Inter, Helvetica;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 30px;
  }

  .desktop-coming-soon .frame-12 {
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 24px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-wrapper {
    background-color: #f1f5fb;
    border-radius: 12px;
    flex: 1;
    justify-content: center;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    padding: 2rem 1.5rem;
    display: flex;
  }

  .desktop-coming-soon .frame-13 {
    flex-direction: column;
    flex: 1;
    align-items: center;
    gap: 24px;
    display: flex;
  }

  .desktop-coming-soon .text-wrapper-8 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.2px;
    align-self: stretch;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }

  .desktop-coming-soon .text-wrapper-9 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.18px;
    align-self: stretch;
    font-family: Inter, Helvetica;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }

  .desktop-coming-soon .frame-14 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 48px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .group-2 {
    width: 74.59px;
    height: 68.5px;
    margin-top: -1.75px;
    margin-bottom: -1.75px;
    margin-left: -1.75px;
  }

  .desktop-coming-soon .text-wrapper-10 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: 0;
    width: fit-content;
    font-family: Inter, Helvetica;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 41.6px;
  }

  .desktop-coming-soon .text-wrapper-11 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.18px;
    align-self: stretch;
    font-family: Inter, Helvetica;
    font-size: 1rem;
    font-weight: 500;
    line-height: 30px;
  }

  .desktop-coming-soon .frame-15 {
    background-color: #f1f5fc;
    border-radius: 12px;
    flex: 1;
    justify-content: center;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    padding: 40px 32px 72px;
    display: flex;
  }

  .desktop-coming-soon .frame-16 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .shopping-cart {
    width: 76px !important;
    height: 76px !important;
  }

  .desktop-coming-soon .frame-17 {
    flex-wrap: wrap;
    flex: 1;
    align-self: stretch;
    align-items: center;
    gap: 24px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-18 {
    background-color: #f1f5fc;
    border-radius: 12px;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 40px 32px 72px;
    display: flex;
  }

  .desktop-coming-soon .about-us {
    background: linear-gradient(#0a2049 0%, #3864b8 100%);
    flex-direction: column;
    align-items: center;
    gap: 72px;
    width: 100%;
    height: fit-content;
    margin-top: 10rem;
    padding: 3rem 1.5rem;
    display: flex;
    overflow: hidden;
  }

  .desktop-coming-soon .frame-19 {
    flex-direction: column;
    flex: none;
    align-items: center;
    gap: 32px;
    display: inline-flex;
  }

  .desktop-coming-soon .text-wrapper-12 {
    color: #fff;
    letter-spacing: -.8px;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 2rem;
    font-weight: 600;
    line-height: 60px;
  }

  .desktop-coming-soon .at-kokorko-we {
    color: #fff;
    letter-spacing: -.4px;
    text-align: center;
    font-family: Inter, Helvetica;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 30px;
  }

  .desktop-coming-soon .footer-bottom-bar {
    flex-direction: column;
    flex: none;
    justify-content: space-between;
    align-self: stretch;
    align-items: center;
    gap: 1rem;
    width: 100%;
    margin-bottom: -52px;
    padding: 32px 0;
    display: flex;
  }

  .desktop-coming-soon .footer-elements {
    flex: none !important;
  }

  .desktop-coming-soon .footer-section-elements {
    color: var(--globalbasewhite) !important;
  }
}

@media (width >= 850px) {
  html {
    scroll-behavior: smooth;
  }

  .desktop-coming-soon .div-2 {
    background-color: #fff;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 88%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
  }

  .button-text-wrapper {
    background-color: var(--kokorkoblueprimary-500);
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 44px;
    padding: 0 16px;
    display: inline-flex;
    overflow: hidden;
  }

  .desktop-coming-soon .button-text-2 {
    color: var(--globalbasewhite);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    white-space: nowrap;
    width: fit-content;
  }

  .desktop-coming-soon .frame-2 {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    display: flex;
  }

  .desktop-coming-soon .kokoroko-logo {
    width: 124px !important;
    height: 42px !important;
  }

  .desktop-coming-soon .frame-3 {
    flex: none;
    align-items: center;
    gap: 32px;
    display: inline-flex;
  }

  .desktop-coming-soon .text-wrapper-2 {
    color: var(--kokorkoblackblack-400);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    white-space: nowrap;
    width: fit-content;
  }

  .desktop-coming-soon .buttons-primary-2 {
    background-color: var(--kokorkoblueprimary-500);
    cursor: pointer;
    border-radius: 6px;
    flex: none;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    display: inline-flex;
    overflow: hidden;
  }

  .desktop-coming-soon .button-text-3 {
    color: var(--globalbasewhite);
    font-family: var(--medium-12-font-family);
    font-size: var(--medium-12-font-size);
    font-style: var(--medium-12-font-style);
    font-weight: var(--medium-12-font-weight);
    letter-spacing: var(--medium-12-letter-spacing);
    line-height: var(--medium-12-line-height);
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
  }

  .desktop-coming-soon .frame-4 {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    width: 100%;
    margin-top: 5rem;
    display: flex;
  }

  .desktop-coming-soon .frame-5 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 16px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-6 {
    flex: none;
    align-items: center;
    gap: 8px;
    display: inline-flex;
  }

  .desktop-coming-soon .icon-park-outline {
    width: 40px;
    height: 40px;
  }

  .desktop-coming-soon .frame-7 {
    flex: none;
    align-items: center;
    gap: 4px;
    display: inline-flex;
  }

  .desktop-coming-soon .text-wrapper-3 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.18px;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }

  .desktop-coming-soon .text-wrapper-4 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -1.44px;
    text-align: center;
    align-self: stretch;
    width: 100%;
    font-family: Inter, Helvetica;
    font-size: 48px;
    font-weight: 600;
    line-height: 57.6px;
    text-wrap: wrap !important;
  }

  .desktop-coming-soon .text-wrapper-5 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.18px;
    text-align: center;
    align-self: stretch;
    font-family: Inter, Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  .desktop-coming-soon .overlap {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90dvh;
    margin-top: 5rem;
    display: flex;
  }

  .group {
    transform: rotate(180.42deg) !important;
  }

  .desktop-coming-soon .overlap-group {
    flex-direction: column;
    display: flex;
  }

  .desktop-coming-soon .ellipse {
    background-color: #ff3405;
    border-radius: 166px;
    width: 50%;
    height: 25%;
    transform: rotate(178.29deg);
    box-shadow: 125px -191.68px 212.98px 95.22px #ff340578;
  }

  .desktop-coming-soon .ellipse-2 {
    background-color: #2752a5;
    border-radius: 166px;
    align-self: flex-end;
    width: 100%;
    height: 100%;
    transform: rotate(178.29deg);
    box-shadow: -224px 191.68px 212.98px 95.22px #2752a58a;
  }

  .desktop-coming-soon .mock-up-wrapper {
    z-index: 1;
    width: 52%;
    height: 53%;
    position: absolute !important;
    top: 36rem !important;
    left: 5rem !important;
  }

  .desktop-coming-soon .mock-up {
    height: auto;
  }

  .desktop-coming-soon .studio-display-front {
    object-fit: cover;
    border: 1rem solid #000;
    border-radius: .5rem;
    width: 100%;
    height: 50%;
    box-sizing: border-box !important;
  }

  .desktop-coming-soon .img {
    z-index: 2;
    width: 60%;
    height: auto;
    position: absolute;
    top: 45rem;
    right: 2rem;
  }

  .desktop-coming-soon .frame-8 {
    flex-direction: column;
    align-items: flex-start;
    gap: 178px;
    width: 100%;
    margin-top: 20rem;
    display: flex;
  }

  .desktop-coming-soon .frame-9 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-end;
    gap: 48px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-10 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-11 {
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 32px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .vector {
    width: 71.5px;
    height: 84px;
  }

  .desktop-coming-soon .text-wrapper-6 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: 0;
    flex: 1;
    font-family: Inter, Helvetica;
    font-size: 32px;
    font-weight: 600;
    line-height: 41.6px;
  }

  .desktop-coming-soon .text-wrapper-7 {
    color: var(--kokorkoblackblack-300);
    letter-spacing: -.18px;
    align-self: stretch;
    font-family: Inter, Helvetica;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }

  .desktop-coming-soon .frame-12 {
    flex-wrap: wrap;
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 24px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-wrapper {
    background-color: #f1f5fb;
    border-radius: 12px;
    flex: 1;
    justify-content: center;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    padding: 40px 88px;
    display: flex;
  }

  .desktop-coming-soon .frame-13 {
    flex-direction: column;
    flex: 1;
    align-items: center;
    gap: 24px;
    display: flex;
  }

  .desktop-coming-soon .text-wrapper-8 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.2px;
    align-self: stretch;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }

  .desktop-coming-soon .text-wrapper-9 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.18px;
    align-self: stretch;
    font-family: Inter, Helvetica;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }

  .desktop-coming-soon .frame-14 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 48px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .group-2 {
    width: 74.59px;
    height: 68.5px;
    margin-top: -1.75px;
    margin-bottom: -1.75px;
    margin-left: -1.75px;
  }

  .desktop-coming-soon .text-wrapper-10 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: 0;
    width: fit-content;
    font-family: Inter, Helvetica;
    font-size: 32px;
    font-weight: 600;
    line-height: 41.6px;
  }

  .desktop-coming-soon .text-wrapper-11 {
    color: var(--kokorkoblackblack-400);
    letter-spacing: -.18px;
    align-self: stretch;
    font-family: Inter, Helvetica;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }

  .desktop-coming-soon .frame-15 {
    background-color: #f1f5fc;
    border-radius: 12px;
    flex: 1;
    justify-content: center;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    padding: 40px 32px 72px;
    display: flex;
  }

  .desktop-coming-soon .frame-16 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .shopping-cart {
    width: 76px !important;
    height: 76px !important;
  }

  .desktop-coming-soon .frame-17 {
    flex-wrap: wrap;
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 24px;
    width: 100%;
    display: flex;
  }

  .desktop-coming-soon .frame-18 {
    background-color: #f1f5fc;
    border-radius: 12px;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 40px 32px 72px;
    display: flex;
  }

  .desktop-coming-soon .about-us {
    background: linear-gradient(90deg, #0a2049 -12.15%, #3864b8 109.79%);
    flex-direction: column;
    align-items: center;
    gap: 72px;
    width: 100%;
    height: fit-content;
    margin-top: 10rem;
    padding: 80px 103px;
    display: flex;
    overflow: hidden;
  }

  .desktop-coming-soon .frame-19 {
    flex-direction: column;
    flex: none;
    align-items: center;
    gap: 32px;
    display: inline-flex;
  }

  .desktop-coming-soon .text-wrapper-12 {
    color: #fff;
    letter-spacing: -.8px;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
  }

  .desktop-coming-soon .at-kokorko-we {
    color: #fff;
    letter-spacing: -.4px;
    text-align: center;
    font-family: Inter, Helvetica;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }

  .desktop-coming-soon .footer-bottom-bar {
    flex-wrap: wrap;
    flex: none;
    justify-content: space-between;
    align-self: stretch;
    align-items: center;
    width: 100%;
    margin-bottom: -52px;
    padding: 32px 0;
    display: flex;
  }

  .desktop-coming-soon .footer-elements {
    flex: none !important;
  }

  .desktop-coming-soon .footer-section-elements {
    color: var(--globalbasewhite) !important;
  }
}
/*# sourceMappingURL=index.e96832d0.css.map */
