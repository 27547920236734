html {
  scroll-behavior: smooth; /* smooth scrolling */
}

.header-section {
  width: 100%;
  background-color: #ffffff;
}

.header-section .header-section-container {
  width: 88%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.8rem 0 1.8rem 0;
}

.header-section .header-section-buttons {
  display: flex;
  gap: 1.2rem;
  align-items: center;
}


@media screen and (max-width: 527px) {
    
  .desktop-coming-soon .div-2 {
    background-color: #ffffff;
    height: fit-content;
    width: 88%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .button-text-wrapper {
    align-items: center;
    background-color: var(--kokorkoblueprimary-500);
    border-radius: 6px;
    display: inline-flex;
    gap: 8px;
    height: 44px;
    justify-content: center;
    overflow: hidden;
    padding: 0px 16px;
  }

  .desktop-coming-soon .button-text-2 {
    color: var(--globalbasewhite);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    white-space: nowrap;
    width: fit-content;
  }

  .desktop-coming-soon .frame-2 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    width: 100%;
  }

  .desktop-coming-soon .kokoroko-logo {
    height: 42px !important;
    width: 124px !important;
  }

  .desktop-coming-soon .frame-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 32px;
  }

  .desktop-coming-soon .text-wrapper-2 {
    color: var(--kokorkoblackblack-400);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    /* position: relative; */
    white-space: nowrap;
    width: fit-content;
    /* cursor: pointer; */
  }

  .desktop-coming-soon .buttons-primary-2 {
    align-items: center;
    background-color: var(--kokorkoblueprimary-500);
    border-radius: 6px;
    /* display: inline-flex; */
    display: none;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    overflow: hidden;
    padding: 8px 12px;
    cursor: pointer;
    /* position: relative; */
  }

  .desktop-coming-soon .button-text-3 {
    color: var(--globalbasewhite);
    font-family: var(--medium-12-font-family);
    font-size: var(--medium-12-font-size);
    font-style: var(--medium-12-font-style);
    font-weight: var(--medium-12-font-weight);
    letter-spacing: var(--medium-12-letter-spacing);
    line-height: var(--medium-12-line-height);
    margin-top: -1px;
    /* position: relative; */
    white-space: nowrap;
    width: fit-content;
  }

  .desktop-coming-soon .frame-4 {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
    margin-top: 5rem;
    width: 90%;
  }

  .desktop-coming-soon .frame-5 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .frame-6 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
  }

  .desktop-coming-soon .icon-park-outline {
    height: 40px;
    width: 40px;
  }

  .desktop-coming-soon .frame-7 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
  }

  .desktop-coming-soon .text-wrapper-3 {
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 32px;
    margin-top: -1px;
    white-space: nowrap;
    width: fit-content;
  }

  .desktop-coming-soon .text-wrapper-4 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 42px;
    font-weight: 600;
    letter-spacing: -1.44px;
    line-height: 57.6px;
    text-align: center;
    width: 100%;
    text-wrap: wrap !important;
  }

  .desktop-coming-soon .text-wrapper-5 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 1rem;
  }

  .desktop-coming-soon .text-wrapper-5 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.18px;
    line-height: 22px;
    text-align: center;
  }

  .desktop-coming-soon .overlap {
    height: 50dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 15rem; */
    width: 100%;
  }

  .group {
    height: 10% !important;
    width: 20% !important;
    transform: rotate(180.42deg) !important;
  }

  .desktop-coming-soon .overlap-group {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .desktop-coming-soon .ellipse {
    background-color: #ff3405;
    border-radius: 166px;
    box-shadow: 125px -191.68px 212.98px 95.22px #ff340578;
    height: 25%;
    width: 50%;
    transform: rotate(178.29deg);
  }

  .desktop-coming-soon .ellipse-2 {
    background-color: #2752a5;
    border-radius: 166px;
    box-shadow: -224px 191.68px 212.98px 95.22px #2752a58a;
    height: 100%;
    transform: rotate(178.29deg);
    width: 100%;
    align-self: flex-end;
  }

  .desktop-coming-soon .mock-up-wrapper {
    height: 53%;
    width: 52%;
    position: absolute !important;
    z-index: 1;
    left:  3rem !important;
    top: 40rem !important;
  }

  .desktop-coming-soon .mock-up {
    height: auto;
  }

  .desktop-coming-soon .studio-display-front {
    height: 50%;
    object-fit: cover;
    width: 100%;
    border: 0.2rem solid #000;
    border-radius: 0.2rem;
    box-sizing: border-box !important;
  }

  .desktop-coming-soon .img {
    height: auto;
    width: 60%;
    position: absolute;
    top: 42rem;
    right: 1.1rem;
    z-index: 2;
  }

  .desktop-coming-soon .frame-8 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 178px;
    width: 100%;
    margin-top: 6rem;
  }

  .desktop-coming-soon .frame-9 {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    width: 100%;
  }

  .desktop-coming-soon .frame-10 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .frame-11 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 32px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .vector {
    height: 84px;
    /* position: relative; */
    width: 71.5px;
  }

  .desktop-coming-soon .text-wrapper-6 {
    color: var(--kokorkoblackblack-400);
    flex: 1;
    font-family: "Inter", Helvetica;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 41.6px;
  }

  .desktop-coming-soon .text-wrapper-7 {
    align-self: stretch;
    color: var(--kokorkoblackblack-300);
    font-family: "Inter", Helvetica;
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 30px;
  }

  .desktop-coming-soon .frame-12 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    gap: 24px;
    /* flex-wrap: wrap; */
    width: 100%;
  }

  .desktop-coming-soon .frame-wrapper {
    align-items: flex-start;
    align-self: stretch;
    background-color: #f1f5fb;
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    padding: 2rem 1.5rem;
  }

  .desktop-coming-soon .frame-13 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    /* position: relative; */
  }

  .desktop-coming-soon .text-wrapper-8 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 24px;
    margin-top: -1px;
    /* position: relative; */
  }

  .desktop-coming-soon .text-wrapper-9 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 21px;
    /* position: relative; */
  }

  .desktop-coming-soon .frame-14 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .group-2 {
    height: 68.5px;
    margin-bottom: -1.75px;
    margin-left: -1.75px;
    margin-top: -1.75px;
    /* position: relative; */
    width: 74.59px;
  }

  .desktop-coming-soon .text-wrapper-10 {
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 41.6px;
    /* position: relative; */
    /* white-space: nowrap; */
    width: fit-content;
  }

  .desktop-coming-soon .text-wrapper-11 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 30px;
    /* position: relative; */
  }

  .desktop-coming-soon .frame-15 {
    align-items: flex-start;
    align-self: stretch;
    background-color: #f1f5fc;
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    padding: 40px 32px 72px;
    /* position: relative; */
  }

  .desktop-coming-soon .frame-16 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .shopping-cart {
    height: 76px !important;
    /* position: relative !important; */
    width: 76px !important;
  }

  .desktop-coming-soon .frame-17 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    /* flex-wrap: wrap; */
    gap: 24px 24px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .frame-18 {
    align-items: flex-start;
    background-color: #f1f5fc;
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    padding: 40px 32px 72px;
    /* position: relative; */
  }

  .desktop-coming-soon .about-us {
    align-items: center;
    background: linear-gradient(180deg, rgb(10.46, 31.8, 72.71) 0%, rgb(56.18, 99.87, 183.6) 100%);
    display: flex;
    flex-direction: column;
    gap: 72px;
    height: fit-content;
    padding: 3rem 1.5rem;
    margin-top: 10rem;
    width: 100%;
    overflow: hidden;
  }

  .desktop-coming-soon .frame-19 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
  }

  .desktop-coming-soon .text-wrapper-12 {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: -0.8px;
    line-height: 60px;
    margin-top: -1px;
  }

  .desktop-coming-soon .at-kokorko-we {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: -0.4px;
    line-height: 30px;
    text-align: center;
  }

  .desktop-coming-soon .footer-bottom-bar {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: -52px;
    padding: 32px 0px;
    width: 100%;
  }

  .desktop-coming-soon .footer-elements {
    flex: 0 0 auto !important;
  }

  .desktop-coming-soon .footer-section-elements {
    color: var(--globalbasewhite) !important;
  }

}

@media (min-width: 528px) and (max-width: 610px) {
    
  .desktop-coming-soon .div-2 {
    background-color: #ffffff;
    height: fit-content;
    width: 88%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .button-text-wrapper {
    align-items: center;
    background-color: var(--kokorkoblueprimary-500);
    border-radius: 6px;
    display: inline-flex;
    gap: 8px;
    height: 44px;
    justify-content: center;
    overflow: hidden;
    padding: 0px 16px;
  }

  .desktop-coming-soon .button-text-2 {
    color: var(--globalbasewhite);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    white-space: nowrap;
    width: fit-content;
  }

  .desktop-coming-soon .frame-2 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    width: 100%;
  }

  .desktop-coming-soon .kokoroko-logo {
    height: 42px !important;
    width: 124px !important;
  }

  .desktop-coming-soon .frame-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 32px;
  }

  .desktop-coming-soon .text-wrapper-2 {
    color: var(--kokorkoblackblack-400);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    /* position: relative; */
    white-space: nowrap;
    width: fit-content;
    /* cursor: pointer; */
  }

  .desktop-coming-soon .buttons-primary-2 {
    align-items: center;
    background-color: var(--kokorkoblueprimary-500);
    border-radius: 6px;
    /* display: inline-flex; */
    display: none;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    overflow: hidden;
    padding: 8px 12px;
    cursor: pointer;
    /* position: relative; */
  }

  .desktop-coming-soon .button-text-3 {
    color: var(--globalbasewhite);
    font-family: var(--medium-12-font-family);
    font-size: var(--medium-12-font-size);
    font-style: var(--medium-12-font-style);
    font-weight: var(--medium-12-font-weight);
    letter-spacing: var(--medium-12-letter-spacing);
    line-height: var(--medium-12-line-height);
    margin-top: -1px;
    /* position: relative; */
    white-space: nowrap;
    width: fit-content;
  }

  .desktop-coming-soon .frame-4 {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
    margin-top: 5rem;
    width: 90%;
  }

  .desktop-coming-soon .frame-5 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .frame-6 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
  }

  .desktop-coming-soon .icon-park-outline {
    height: 40px;
    width: 40px;
  }

  .desktop-coming-soon .frame-7 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
  }

  .desktop-coming-soon .text-wrapper-3 {
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 32px;
    margin-top: -1px;
    white-space: nowrap;
    width: fit-content;
  }

  .desktop-coming-soon .text-wrapper-4 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 42px;
    font-weight: 600;
    letter-spacing: -1.44px;
    line-height: 57.6px;
    text-align: center;
    width: 100%;
    text-wrap: wrap !important;
  }

  .desktop-coming-soon .text-wrapper-5 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 1rem;
  }

  .desktop-coming-soon .text-wrapper-5 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.18px;
    line-height: 22px;
    text-align: center;
  }

  .desktop-coming-soon .overlap {
    height: 50dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 15rem; */
    width: 100%;
  }

  .group {
    height: 10% !important;
    width: 20% !important;
    transform: rotate(180.42deg) !important;
  }

  .desktop-coming-soon .overlap-group {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .desktop-coming-soon .ellipse {
    background-color: #ff3405;
    border-radius: 166px;
    box-shadow: 125px -191.68px 212.98px 95.22px #ff340578;
    height: 25%;
    width: 50%;
    transform: rotate(178.29deg);
  }

  .desktop-coming-soon .ellipse-2 {
    background-color: #2752a5;
    border-radius: 166px;
    box-shadow: -224px 191.68px 212.98px 95.22px #2752a58a;
    height: 100%;
    transform: rotate(178.29deg);
    width: 100%;
    align-self: flex-end;
  }

  .desktop-coming-soon .mock-up-wrapper {
    height: 53%;
    width: 52%;
    position: absolute !important;
    z-index: 1;
    left: 3rem !important;
    top: 35rem !important;
  }

  .desktop-coming-soon .mock-up {
    height: auto;
  }

  .desktop-coming-soon .studio-display-front {
    height: 50%;
    object-fit: cover;
    width: 100%;
    border: 0.2rem solid #000;
    border-radius: 0.2rem;
    box-sizing: border-box !important;
  }

  .desktop-coming-soon .img {
    height: auto;
    width: 60%;
    position: absolute;
    top: 38rem;
    right: 1.1rem;
    z-index: 2;
  }

  .desktop-coming-soon .frame-8 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 178px;
    width: 100%;
    margin-top: 8rem;
  }

  .desktop-coming-soon .frame-9 {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    width: 100%;
  }

  .desktop-coming-soon .frame-10 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .frame-11 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 32px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .vector {
    height: 84px;
    /* position: relative; */
    width: 71.5px;
  }

  .desktop-coming-soon .text-wrapper-6 {
    color: var(--kokorkoblackblack-400);
    flex: 1;
    font-family: "Inter", Helvetica;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 41.6px;
  }

  .desktop-coming-soon .text-wrapper-7 {
    align-self: stretch;
    color: var(--kokorkoblackblack-300);
    font-family: "Inter", Helvetica;
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 30px;
  }

  .desktop-coming-soon .frame-12 {
    align-items: center;
    align-self: stretch;
    display: flex;
    /* flex-direction: column; */
    flex: 0 0 auto;
    gap: 24px;
    /* flex-wrap: wrap; */
    width: 100%;
  }

  .desktop-coming-soon .frame-wrapper {
    align-items: flex-start;
    align-self: stretch;
    background-color: #f1f5fb;
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    padding: 2rem 1.5rem;
  }

  .desktop-coming-soon .frame-13 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    /* position: relative; */
  }

  .desktop-coming-soon .text-wrapper-8 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 24px;
    margin-top: -1px;
    /* position: relative; */
  }

  .desktop-coming-soon .text-wrapper-9 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 21px;
    /* position: relative; */
  }

  .desktop-coming-soon .frame-14 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .group-2 {
    height: 68.5px;
    margin-bottom: -1.75px;
    margin-left: -1.75px;
    margin-top: -1.75px;
    /* position: relative; */
    width: 74.59px;
  }

  .desktop-coming-soon .text-wrapper-10 {
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 41.6px;
    /* position: relative; */
    /* white-space: nowrap; */
    width: fit-content;
  }

  .desktop-coming-soon .text-wrapper-11 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 30px;
    /* position: relative; */
  }

  .desktop-coming-soon .frame-15 {
    align-items: flex-start;
    align-self: stretch;
    background-color: #f1f5fc;
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    padding: 40px 32px 72px;
    /* position: relative; */
  }

  .desktop-coming-soon .frame-16 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .shopping-cart {
    height: 76px !important;
    /* position: relative !important; */
    width: 76px !important;
  }

  .desktop-coming-soon .frame-17 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    /* flex-wrap: wrap; */
    gap: 24px 24px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .frame-18 {
    align-items: flex-start;
    background-color: #f1f5fc;
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    padding: 40px 32px 72px;
    /* position: relative; */
  }

  .desktop-coming-soon .about-us {
    align-items: center;
    background: linear-gradient(180deg, rgb(10.46, 31.8, 72.71) 0%, rgb(56.18, 99.87, 183.6) 100%);
    display: flex;
    flex-direction: column;
    gap: 72px;
    height: fit-content;
    padding: 3rem 1.5rem;
    margin-top: 10rem;
    width: 100%;
    overflow: hidden;
  }

  .desktop-coming-soon .frame-19 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
  }

  .desktop-coming-soon .text-wrapper-12 {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: -0.8px;
    line-height: 60px;
    margin-top: -1px;
  }

  .desktop-coming-soon .at-kokorko-we {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: -0.4px;
    line-height: 30px;
    text-align: center;
  }

  .desktop-coming-soon .footer-bottom-bar {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: -52px;
    padding: 32px 0px;
    width: 100%;
  }

  .desktop-coming-soon .footer-elements {
    flex: 0 0 auto !important;
  }

  .desktop-coming-soon .footer-section-elements {
    color: var(--globalbasewhite) !important;
  }

}

@media (min-width: 611px) and (max-width: 849px) {
    
  .desktop-coming-soon .div-2 {
    background-color: #ffffff;
    height: fit-content;
    width: 88%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .button-text-wrapper {
    align-items: center;
    background-color: var(--kokorkoblueprimary-500);
    border-radius: 6px;
    display: inline-flex;
    gap: 8px;
    height: 44px;
    justify-content: center;
    overflow: hidden;
    padding: 0px 16px;
  }

  .desktop-coming-soon .button-text-2 {
    color: var(--globalbasewhite);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    white-space: nowrap;
    width: fit-content;
  }

  .desktop-coming-soon .frame-2 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    width: 100%;
  }

  .desktop-coming-soon .kokoroko-logo {
    height: 42px !important;
    width: 124px !important;
  }

  .desktop-coming-soon .frame-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 32px;
  }

  .desktop-coming-soon .text-wrapper-2 {
    color: var(--kokorkoblackblack-400);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    /* position: relative; */
    white-space: nowrap;
    width: fit-content;
    /* cursor: pointer; */
  }

  .desktop-coming-soon .buttons-primary-2 {
    align-items: center;
    background-color: var(--kokorkoblueprimary-500);
    border-radius: 6px;
    /* display: inline-flex; */
    display: none;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    overflow: hidden;
    padding: 8px 12px;
    cursor: pointer;
    /* position: relative; */
  }

  .desktop-coming-soon .button-text-3 {
    color: var(--globalbasewhite);
    font-family: var(--medium-12-font-family);
    font-size: var(--medium-12-font-size);
    font-style: var(--medium-12-font-style);
    font-weight: var(--medium-12-font-weight);
    letter-spacing: var(--medium-12-letter-spacing);
    line-height: var(--medium-12-line-height);
    margin-top: -1px;
    /* position: relative; */
    white-space: nowrap;
    width: fit-content;
  }

  .desktop-coming-soon .frame-4 {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
    margin-top: 5rem;
    width: 90%;
  }

  .desktop-coming-soon .frame-5 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .frame-6 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
  }

  .desktop-coming-soon .icon-park-outline {
    height: 40px;
    width: 40px;
  }

  .desktop-coming-soon .frame-7 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
  }

  .desktop-coming-soon .text-wrapper-3 {
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 32px;
    margin-top: -1px;
    white-space: nowrap;
    width: fit-content;
  }

  .desktop-coming-soon .text-wrapper-4 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 42px;
    font-weight: 600;
    letter-spacing: -1.44px;
    line-height: 57.6px;
    text-align: center;
    width: 100%;
    text-wrap: wrap !important;
  }

  .desktop-coming-soon .text-wrapper-5 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 1rem;
  }

  .desktop-coming-soon .text-wrapper-5 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.18px;
    line-height: 22px;
    text-align: center;
  }

  .desktop-coming-soon .overlap {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15rem;
    width: 100%;
  }

  .group {
    height: 10% !important;
    width: 20% !important;
    transform: rotate(180.42deg) !important;
  }

  .desktop-coming-soon .overlap-group {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .desktop-coming-soon .ellipse {
    background-color: #ff3405;
    border-radius: 166px;
    box-shadow: 125px -191.68px 212.98px 95.22px #ff340578;
    height: 25%;
    width: 50%;
    transform: rotate(178.29deg);
  }

  .desktop-coming-soon .ellipse-2 {
    background-color: #2752a5;
    border-radius: 166px;
    box-shadow: -224px 191.68px 212.98px 95.22px #2752a58a;
    height: 100%;
    transform: rotate(178.29deg);
    width: 100%;
    align-self: flex-end;
  }

  .desktop-coming-soon .mock-up-wrapper {
    height: 53%;
    width: 52%;
    position: absolute !important;
    z-index: 1;
    left: 6rem !important;
    top: 40rem !important;
  }

  .desktop-coming-soon .mock-up {
    height: auto;
  }

  .desktop-coming-soon .studio-display-front {
    height: 50%;
    object-fit: cover;
    width: 100%;
    border: 0.2rem solid #000;
    border-radius: 0.2rem;
    box-sizing: border-box !important;
  }

  .desktop-coming-soon .img {
    height: auto;
    width: 60%;
    position: absolute;
    top: 45rem;
    right: 2rem;
    z-index: 2;
  }

  .desktop-coming-soon .frame-8 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 178px;
    width: 100%;
    margin-top: 25rem;
  }

  .desktop-coming-soon .frame-9 {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    width: 100%;
  }

  .desktop-coming-soon .frame-10 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .frame-11 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 32px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .vector {
    height: 84px;
    /* position: relative; */
    width: 71.5px;
  }

  .desktop-coming-soon .text-wrapper-6 {
    color: var(--kokorkoblackblack-400);
    flex: 1;
    font-family: "Inter", Helvetica;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 41.6px;
  }

  .desktop-coming-soon .text-wrapper-7 {
    align-self: stretch;
    color: var(--kokorkoblackblack-300);
    font-family: "Inter", Helvetica;
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 30px;
  }

  .desktop-coming-soon .frame-12 {
    align-items: center;
    align-self: stretch;
    display: flex;
    /* flex-direction: column; */
    flex: 0 0 auto;
    gap: 24px;
    /* flex-wrap: wrap; */
    width: 100%;
  }

  .desktop-coming-soon .frame-wrapper {
    align-items: flex-start;
    align-self: stretch;
    background-color: #f1f5fb;
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    padding: 2rem 1.5rem;
  }

  .desktop-coming-soon .frame-13 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    /* position: relative; */
  }

  .desktop-coming-soon .text-wrapper-8 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 24px;
    margin-top: -1px;
    /* position: relative; */
  }

  .desktop-coming-soon .text-wrapper-9 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 21px;
    /* position: relative; */
  }

  .desktop-coming-soon .frame-14 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .group-2 {
    height: 68.5px;
    margin-bottom: -1.75px;
    margin-left: -1.75px;
    margin-top: -1.75px;
    /* position: relative; */
    width: 74.59px;
  }

  .desktop-coming-soon .text-wrapper-10 {
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 41.6px;
    /* position: relative; */
    /* white-space: nowrap; */
    width: fit-content;
  }

  .desktop-coming-soon .text-wrapper-11 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 30px;
    /* position: relative; */
  }

  .desktop-coming-soon .frame-15 {
    align-items: flex-start;
    align-self: stretch;
    background-color: #f1f5fc;
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    padding: 40px 32px 72px;
    /* position: relative; */
  }

  .desktop-coming-soon .frame-16 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .shopping-cart {
    height: 76px !important;
    /* position: relative !important; */
    width: 76px !important;
  }

  .desktop-coming-soon .frame-17 {
    align-items: center;
    align-self: stretch;
    display: flex;
    /* flex-direction: column; */
    flex: 1;
    flex-wrap: wrap;
    gap: 24px 24px;
    /* position: relative; */
    width: 100%;
  }

  .desktop-coming-soon .frame-18 {
    align-items: flex-start;
    background-color: #f1f5fc;
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    padding: 40px 32px 72px;
    /* position: relative; */
  }

  .desktop-coming-soon .about-us {
    align-items: center;
    background: linear-gradient(180deg, rgb(10.46, 31.8, 72.71) 0%, rgb(56.18, 99.87, 183.6) 100%);
    display: flex;
    flex-direction: column;
    gap: 72px;
    height: fit-content;
    padding: 3rem 1.5rem;
    margin-top: 10rem;
    width: 100%;
    overflow: hidden;
  }

  .desktop-coming-soon .frame-19 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
  }

  .desktop-coming-soon .text-wrapper-12 {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: -0.8px;
    line-height: 60px;
    margin-top: -1px;
  }

  .desktop-coming-soon .at-kokorko-we {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: -0.4px;
    line-height: 30px;
    text-align: center;
  }

  .desktop-coming-soon .footer-bottom-bar {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: -52px;
    padding: 32px 0px;
    width: 100%;
  }

  .desktop-coming-soon .footer-elements {
    flex: 0 0 auto !important;
  }

  .desktop-coming-soon .footer-section-elements {
    color: var(--globalbasewhite) !important;
  }

}

@media (min-width: 850px) {
  html {
    scroll-behavior: smooth; /* smooth scrolling */
  }
  
  .desktop-coming-soon .div-2 {
    background-color: #ffffff;
    height: fit-content;
    width: 88%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .button-text-wrapper {
    align-items: center;
    background-color: var(--kokorkoblueprimary-500);
    border-radius: 6px;
    display: inline-flex;
    gap: 8px;
    height: 44px;
    justify-content: center;
    overflow: hidden;
    padding: 0px 16px;
  }
  
  .desktop-coming-soon .button-text-2 {
    color: var(--globalbasewhite);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    white-space: nowrap;
    width: fit-content;
  }
  
  .desktop-coming-soon .frame-2 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    width: 100%;
  }
  
  .desktop-coming-soon .kokoroko-logo {
    height: 42px !important;
    width: 124px !important;
  }
  
  .desktop-coming-soon .frame-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 32px;
  }
  
  .desktop-coming-soon .text-wrapper-2 {
    color: var(--kokorkoblackblack-400);
    font-family: var(--semibold-16-font-family);
    font-size: var(--semibold-16-font-size);
    font-style: var(--semibold-16-font-style);
    font-weight: var(--semibold-16-font-weight);
    letter-spacing: var(--semibold-16-letter-spacing);
    line-height: var(--semibold-16-line-height);
    /* position: relative; */
    white-space: nowrap;
    width: fit-content;
    /* cursor: pointer; */
  }
  
  .desktop-coming-soon .buttons-primary-2 {
    align-items: center;
    background-color: var(--kokorkoblueprimary-500);
    border-radius: 6px;
    display: inline-flex;
    /* display: none; */
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    overflow: hidden;
    padding: 8px 12px;
    cursor: pointer;
    /* position: relative; */
  }
  
  .desktop-coming-soon .button-text-3 {
    color: var(--globalbasewhite);
    font-family: var(--medium-12-font-family);
    font-size: var(--medium-12-font-size);
    font-style: var(--medium-12-font-style);
    font-weight: var(--medium-12-font-weight);
    letter-spacing: var(--medium-12-letter-spacing);
    line-height: var(--medium-12-line-height);
    margin-top: -1px;
    /* position: relative; */
    white-space: nowrap;
    width: fit-content;
  }
  
  .desktop-coming-soon .frame-4 {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
    margin-top: 5rem;
    width: 100%;
  }
  
  .desktop-coming-soon .frame-5 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    /* position: relative; */
    width: 100%;
  }
  
  .desktop-coming-soon .frame-6 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
  }
  
  .desktop-coming-soon .icon-park-outline {
    height: 40px;
    width: 40px;
  }
  
  .desktop-coming-soon .frame-7 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
  }
  
  .desktop-coming-soon .text-wrapper-3 {
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 32px;
    margin-top: -1px;
    white-space: nowrap;
    width: fit-content;
  }
  
  .desktop-coming-soon .text-wrapper-4 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: -1.44px;
    line-height: 57.6px;
    text-align: center;
    width: 100%;
    text-wrap: wrap !important;
  }
  
  .desktop-coming-soon .text-wrapper-5 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.18px;
    line-height: 22px;
    text-align: center;
  }
  
  .desktop-coming-soon .overlap {
    height: 90dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    /* padding-bottom: 4rem; */
    width: 100%;
  }
  
  .group {
    /* height: 20% !important;
    width: 40% !important; */
    transform: rotate(180.42deg) !important;
  }
  
  .desktop-coming-soon .overlap-group {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
  }
  
  .desktop-coming-soon .ellipse {
    background-color: #ff3405;
    border-radius: 166px;
    box-shadow: 125px -191.68px 212.98px 95.22px #ff340578;
    height: 25%;
    width: 50%;
    transform: rotate(178.29deg);
  }
  
  .desktop-coming-soon .ellipse-2 {
    background-color: #2752a5;
    border-radius: 166px;
    box-shadow: -224px 191.68px 212.98px 95.22px #2752a58a;
    height: 100%;
    transform: rotate(178.29deg);
    width: 100%;
    align-self: flex-end;
  }
  
  .desktop-coming-soon .mock-up-wrapper {
    height: 53%;
    width: 52%;
    position: absolute !important;
    z-index: 1;
    left:  5rem !important;
    top: 36rem !important;
  }
  
  .desktop-coming-soon .mock-up {
    height: auto;
  }
  
  .desktop-coming-soon .studio-display-front {
    height: 50%;
    object-fit: cover;
    width: 100%;
    border: 1rem solid #000;
    border-radius: 0.5rem;
    box-sizing: border-box !important;
  }
  
  .desktop-coming-soon .img {
    height: auto;
    width: 60%;
    position: absolute;
    top: 45rem;
    right: 2rem;
    z-index: 2;
  }
  
  .desktop-coming-soon .frame-8 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 178px;
    width: 100%;
    margin-top: 20rem;
  }
  
  .desktop-coming-soon .frame-9 {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    width: 100%;
  }
  
  .desktop-coming-soon .frame-10 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    /* position: relative; */
    width: 100%;
  }
  
  .desktop-coming-soon .frame-11 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 32px;
    /* position: relative; */
    width: 100%;
  }
  
  .desktop-coming-soon .vector {
    height: 84px;
    /* position: relative; */
    width: 71.5px;
  }
  
  .desktop-coming-soon .text-wrapper-6 {
    color: var(--kokorkoblackblack-400);
    flex: 1;
    font-family: "Inter", Helvetica;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 41.6px;
    /* position: relative; */
  }
  
  .desktop-coming-soon .text-wrapper-7 {
    align-self: stretch;
    color: var(--kokorkoblackblack-300);
    font-family: "Inter", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 30px;
    /* position: relative; */
  }
  
  .desktop-coming-soon .frame-12 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 24px;
    flex-wrap: wrap;
    /* border: 1px solid blue; */
    /* position: relative; */
    width: 100%;
  }
  
  .desktop-coming-soon .frame-wrapper {
    align-items: flex-start;
    align-self: stretch;
    background-color: #f1f5fb;
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    padding: 40px 88px;
    /* border: 1px solid #ff3405; */
    /* position: relative; */
  }
  
  .desktop-coming-soon .frame-13 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    /* position: relative; */
  }
  
  .desktop-coming-soon .text-wrapper-8 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 24px;
    margin-top: -1px;
    /* position: relative; */
  }
  
  .desktop-coming-soon .text-wrapper-9 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 21px;
    /* position: relative; */
  }
  
  .desktop-coming-soon .frame-14 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    /* position: relative; */
    width: 100%;
  }
  
  .desktop-coming-soon .group-2 {
    height: 68.5px;
    margin-bottom: -1.75px;
    margin-left: -1.75px;
    margin-top: -1.75px;
    /* position: relative; */
    width: 74.59px;
  }
  
  .desktop-coming-soon .text-wrapper-10 {
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 41.6px;
    /* position: relative; */
    /* white-space: nowrap; */
    width: fit-content;
  }
  
  .desktop-coming-soon .text-wrapper-11 {
    align-self: stretch;
    color: var(--kokorkoblackblack-400);
    font-family: "Inter", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 30px;
    /* position: relative; */
  }
  
  .desktop-coming-soon .frame-15 {
    align-items: flex-start;
    align-self: stretch;
    background-color: #f1f5fc;
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    padding: 40px 32px 72px;
    /* position: relative; */
  }
  
  .desktop-coming-soon .frame-16 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    /* position: relative; */
    width: 100%;
  }
  
  .desktop-coming-soon .shopping-cart {
    height: 76px !important;
    /* position: relative !important; */
    width: 76px !important;
  }
  
  .desktop-coming-soon .frame-17 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    gap: 24px 24px;
    /* position: relative; */
    width: 100%;
  }
  
  .desktop-coming-soon .frame-18 {
    align-items: flex-start;
    background-color: #f1f5fc;
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    padding: 40px 32px 72px;
    /* position: relative; */
  }
  
  .desktop-coming-soon .about-us {
    align-items: center;
    /* background: linear-gradient(180deg, rgb(10.46, 31.8, 72.71) 0%, rgb(56.18, 99.87, 183.6) 100%); */
    background: linear-gradient(90deg, #0A2049 -12.15%, #3864B8 109.79%);
    display: flex;
    flex-direction: column;
    gap: 72px;
    height: fit-content;
    /* left: 0; */
    padding: 80px 103px;
    margin-top: 10rem;
    width: 100%;
    overflow: hidden;
  }
  
  .desktop-coming-soon .frame-19 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    /* position: relative; */
  }
  
  .desktop-coming-soon .text-wrapper-12 {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: -0.8px;
    line-height: 60px;
    margin-top: -1px;
  }
  
  .desktop-coming-soon .at-kokorko-we {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.4px;
    line-height: 30px;
    /* position: relative; */
    text-align: center;
    /* width: 100%; */
  }
  
  .desktop-coming-soon .footer-bottom-bar {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: -52px;
    padding: 32px 0px;
    /* position: relative; */
    width: 100%;
  }
  
  .desktop-coming-soon .footer-elements {
    flex: 0 0 auto !important;
  }
  
  .desktop-coming-soon .footer-section-elements {
    color: var(--globalbasewhite) !important;
  }
  
}