.footer-section {
  align-items: flex-start;
  /* position: relative; */
}

.footer-section .item-text-input {
  align-items: center;
  box-shadow: var(--shadow-hard-small);
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* position: relative; */
}

.footer-section .frame {
  align-items: center;
  align-self: stretch;
  background-color: var(--globalbasewhite);
  border: 1px solid;
  border-color: var(--global-neutral-grey-500);
  border-radius: 8px;
  box-shadow: var(--shadow-soft-2x-small);
  display: flex;
  gap: 8px;
  height: 40px;
  overflow: hidden;
  padding: 0px 12px;
  /* position: relative; */
  width: 100%;
}

.footer-section .container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 4px;
  /* position: relative; */
}

.footer-section .texthead-title {
  color: var(--global-neutral-grey-900);
  font-family: var(--regular-14-font-family);
  font-size: var(--regular-14-font-size);
  font-style: var(--regular-14-font-style);
  font-weight: var(--regular-14-font-weight);
  letter-spacing: var(--regular-14-letter-spacing);
  line-height: var(--regular-14-line-height);
  margin-top: -1px;
  /* position: relative; */
  white-space: nowrap;
  width: fit-content;
}

.footer-section .buttons-primary {
  all: unset;
  align-items: center;
  background-color: var(--globalblue-900);
  border-radius: 7px;
  box-shadow: var(--shadow-hard-small);
  box-sizing: border-box;
  gap: 8px;
  height: 40px;
  justify-content: center;
  padding: 0px 14px;
  /* position: relative; */
}

.footer-section .button-text {
  all: unset;
  box-sizing: border-box;
  color: var(--global-base-white);
  font-family: var(--semibold-14-font-family);
  font-size: var(--semibold-14-font-size);
  font-style: var(--semibold-14-font-style);
  font-weight: var(--semibold-14-font-weight);
  letter-spacing: var(--semibold-14-letter-spacing);
  line-height: var(--semibold-14-line-height);
  /* position: relative; */
  white-space: nowrap;
  width: fit-content;
}

.footer-section .footer-section-link {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  height: 24px;
  /* position: relative; */
}

.footer-section .link {
  color: var(--global-neutral-grey-1100);
  font-family: var(--medium-14-font-family);
  font-size: var(--medium-14-font-size);
  font-style: var(--medium-14-font-style);
  font-weight: var(--medium-14-font-weight);
  letter-spacing: var(--medium-14-letter-spacing);
  line-height: var(--medium-14-line-height);
  /* position: relative; */
  white-space: nowrap;
  width: fit-content;
}

.footer-section .link-wrapper {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  height: 24px;
  /* position: relative; */
}

.footer-section .div-wrapper {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  height: 24px;
  /* position: relative; */
}

.footer-section .div {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  height: 24px;
  /* position: relative; */
}

.footer-section .footer-section-link-2 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  height: 24px;
  /* position: relative; */
}

.footer-section .footer-section-link-3 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  height: 24px;
  /* position: relative; */
}

.footer-section .subhead-title {
  color: var(--global-neutral-grey-1000);
  margin-top: -1px;
  /* position: relative; */
  white-space: nowrap;
  width: fit-content;
}

.footer-section .misc-icons-social {
  height: 16px !important;
  /* position: relative !important; */
  width: 16px !important;
}

.footer-section .text-wrapper {
  color: var(--global-neutral-grey-1000);
  font-family: var(--medium-14-font-family);
  font-size: var(--medium-14-font-size);
  font-style: var(--medium-14-font-style);
  font-weight: var(--medium-14-font-weight);
  letter-spacing: var(--medium-14-letter-spacing);
  line-height: var(--medium-14-line-height);
  margin-top: -1px;
  /* position: relative; */
  white-space: nowrap;
  width: fit-content;
}

.footer-section .header-nav-bar-logo {
  flex: 0 0 auto !important;
  margin-left: -2.5px !important;
  margin-top: -1.5px !important;
  /* position: relative !important; */
}

.footer-section .p {
  color: var(--global-neutral-grey-1000);
  font-family: var(--regular-14-font-family);
  font-size: var(--regular-14-font-size);
  font-style: var(--regular-14-font-style);
  font-weight: var(--regular-14-font-weight);
  letter-spacing: var(--regular-14-letter-spacing);
  line-height: var(--regular-14-line-height);
  /* position: relative; */
  width: 25%;
}

.footer-section.type-subscribe {
  display: flex;
  gap: 12px;
  width: 26%;
}

.footer-section.type-subscribe-stacked {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 26%;
}

.footer-section.type-basic-nav {
  display: inline-flex;
  gap: 24px;
}

.footer-section.type-basic-nav-vertical {
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
}

.footer-section.type-text {
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
}

.footer-section.type-footer-section {
  display: inline-flex;
  gap: 24px;
}

.footer-section.type-logo {
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
}

.footer-section.type-copyright {
  display: inline-flex;
  gap: 24px;
}

.footer-section.type-socials {
  display: inline-flex;
  gap: 24px;
}

.footer-section.type-subscribe .item-text-input {
  flex: 1;
  flex-grow: 1;
}

.footer-section.type-subscribe-stacked .item-text-input {
  align-self: stretch;
  flex: 0 0 auto;
  width: 100%;
}

.footer-section.type-basic-nav .item-text-input {
  flex: 1;
}

.footer-section.type-basic-nav-vertical .item-text-input {
  flex: 1;
}

.footer-section.type-text .item-text-input {
  flex: 1;
}

.footer-section.type-footer-section .item-text-input {
  flex: 1;
}

.footer-section.type-logo .item-text-input {
  flex: 1;
}

.footer-section.type-copyright .item-text-input {
  flex: 1;
}

.footer-section.type-socials .item-text-input {
  flex: 1;
}

.footer-section.type-subscribe .buttons-primary {
  display: inline-flex;
  flex: 0 0 auto;
}

.footer-section.type-subscribe-stacked .buttons-primary {
  align-self: stretch;
  display: flex;
  width: 100%;
}

.footer-section.type-basic-nav .buttons-primary {
  display: inline-flex;
}

.footer-section.type-basic-nav-vertical .buttons-primary {
  display: inline-flex;
}

.footer-section.type-text .buttons-primary {
  display: inline-flex;
}

.footer-section.type-footer-section .buttons-primary {
  display: inline-flex;
}

.footer-section.type-logo .buttons-primary {
  display: inline-flex;
}

.footer-section.type-copyright .buttons-primary {
  display: inline-flex;
}

.footer-section.type-socials .buttons-primary {
  display: inline-flex;
}

.footer-section.type-basic-nav .footer-section-link {
  flex: 0 0 auto;
}

.footer-section.type-basic-nav .link-wrapper {
  flex: 0 0 auto;
}

.footer-section.type-basic-nav .div-wrapper {
  flex: 0 0 auto;
}

.footer-section.type-basic-nav .div {
  flex: 0 0 auto;
}

.footer-section.type-basic-nav .footer-section-link-2 {
  flex: 0 0 auto;
}

.footer-section.type-basic-nav .footer-section-link-3 {
  flex: 0 0 auto;
}

.footer-section.type-subscribe .subhead-title {
  font-family: var(--medium-14-font-family);
  font-size: var(--medium-14-font-size);
  font-style: var(--medium-14-font-style);
  font-weight: var(--medium-14-font-weight);
  letter-spacing: var(--medium-14-letter-spacing);
  line-height: var(--medium-14-line-height);
}

.footer-section.type-subscribe-stacked .subhead-title {
  font-family: var(--medium-14-font-family);
  font-size: var(--medium-14-font-size);
  font-style: var(--medium-14-font-style);
  font-weight: var(--medium-14-font-weight);
  letter-spacing: var(--medium-14-letter-spacing);
  line-height: var(--medium-14-line-height);
}

.footer-section.type-basic-nav .subhead-title {
  font-family: var(--medium-14-font-family);
  font-size: var(--medium-14-font-size);
  font-style: var(--medium-14-font-style);
  font-weight: var(--medium-14-font-weight);
  letter-spacing: var(--medium-14-letter-spacing);
  line-height: var(--medium-14-line-height);
}

.footer-section.type-basic-nav-vertical .subhead-title {
  font-family: var(--medium-14-font-family);
  font-size: var(--medium-14-font-size);
  font-style: var(--medium-14-font-style);
  font-weight: var(--medium-14-font-weight);
  letter-spacing: var(--medium-14-letter-spacing);
  line-height: var(--medium-14-line-height);
}

.footer-section.type-text .subhead-title {
  font-family: var(--medium-14-font-family);
  font-size: var(--medium-14-font-size);
  font-style: var(--medium-14-font-style);
  font-weight: var(--medium-14-font-weight);
  letter-spacing: var(--medium-14-letter-spacing);
  line-height: var(--medium-14-line-height);
}

.footer-section.type-footer-section .subhead-title {
  font-family: var(--medium-14-font-family);
  font-size: var(--medium-14-font-size);
  font-style: var(--medium-14-font-style);
  font-weight: var(--medium-14-font-weight);
  letter-spacing: var(--medium-14-letter-spacing);
  line-height: var(--medium-14-line-height);
}

.footer-section.type-logo .subhead-title {
  font-family: var(--medium-14-font-family);
  font-size: var(--medium-14-font-size);
  font-style: var(--medium-14-font-style);
  font-weight: var(--medium-14-font-weight);
  letter-spacing: var(--medium-14-letter-spacing);
  line-height: var(--medium-14-line-height);
}

.footer-section.type-copyright .subhead-title {
  font-family: var(--regular-14-font-family);
  font-size: var(--regular-14-font-size);
  font-style: var(--regular-14-font-style);
  font-weight: var(--regular-14-font-weight);
  letter-spacing: var(--regular-14-letter-spacing);
  line-height: var(--regular-14-line-height);
}

.footer-section.type-socials .subhead-title {
  font-family: var(--medium-14-font-family);
  font-size: var(--medium-14-font-size);
  font-style: var(--medium-14-font-style);
  font-weight: var(--medium-14-font-weight);
  letter-spacing: var(--medium-14-letter-spacing);
  line-height: var(--medium-14-line-height);
}

.footer-section.type-text .p {
  margin-top: -1px;
}
